@keyframes reveal {
  0% {
    opacity: 1;
    border: 3px solid red;
  }

  100% {
    opacity: 0;
    border: 1px solid red;
  }
}

.component {
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
}

.box {
  animation: reveal 1s linear forwards;
  position: absolute;
  top: 0;
  left: 0;

  &:hover {
    opacity: 1 !important;
    z-index: 1000000 !important;

    .tip {
      display: block !important;
    }
  }

  .tip {
    transform: translate3d(0, -50%, 0);
    position: absolute;
    top: 50%;
    left: 100%;
    margin: 0 0 0 10px;
    padding: 10px;
    border-radius: 9px;
    background: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    pointer-events: none;
    display: none;
    color: white;
  }
}

.showBoxes .box {
  opacity: 1 !important;

  .tip {
    display: none;
  }
}
