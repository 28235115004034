
.groupEvent {
  position: relative;
  transform: translate3d(var(--x), -50%, 0);
  position: absolute;
  bottom: 132px;
  right: -15px;
  pointer-events: none;

  &:after {
    transform: translate3d(calc(-50% - 0.5px), 0.5px, 0);
    content: '';
    position: absolute;
    bottom: 15px;
    left: 50%;
    width: 1px;
    height: 24px;
    background: rgba(255, 255, 255, 0.5);
  }

  .groupEvents {
    transform: translate3d(-50%, 0.5px, 0);
    position: absolute;
    display: flex;
    flex-direction: row;
    padding: 0 0 15px 0;
    bottom: 39px;
    left: 50%;

    &:after {
      transform: translate3d(-50%, 0, 0);
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: calc(100% - 15px - 15px);
      height: 1px;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.5);
    }
  }

  .groupLine {
    width: 30px;
    height: 30px;
  }

  .groupEventWrapper {
    position: relative;
    margin: 0 25px 0 0;

    &:after {
      transform: translate3d(-50%, 0, 0);
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 1px;
      height: 15px;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.5);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .timelineEvent {
    transform: none;
    position: static;
    margin: 0;
  }
}

.timelineDate {
  transform: translate3d(-50%, 0, 0);
  position: absolute;
  top: 100%;
  left: 50%;
  transition: color 250ms ease, opacity 250ms ease;
  padding: 13px 0 0 0;
  font-size: 11px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  white-space: nowrap;
}