.component {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.input {
  flex-grow: 1;
  padding: 12px !important;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  outline: 0;
  color: rgba(255, 255, 255, 0.5);

  &:focus {
    color: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, 0.25);
  }

  &.description {
    margin: 10px 0 0 0;
    resize: none;
  }

  &:invalid, &.invalid {
    border-color: #75140C;
    background-color: rgba(117, 20, 12, 0.2);
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

.modal {
  background: #000;
  border-radius: 9px;
  box-shadow: 0 6px 18px 2px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.createSelect {
  display: flex;
  flex-direction: row;
}

.urlStep {
  font-size: 17px;

  .border {
    padding: 25px;
  }

  .field {
    display: flex;
    flex-direction: column;

    .label {
      padding: 0 0 10px 0;
    }
  }

  .input {
    width: 350px;
  }
}

.buttons {
  display: flex;
  margin: 25px 0 0 0;
  padding: 15px 0 0 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  > :last-child {
    margin-left: auto !important;
  }
}
