.scrubber {
  transition: opacity 250ms ease;
  position: absolute;
  width: 160px;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 2;
}

.isActive {
  opacity: 1;
}

.thumb {
  width: 160px;
  height: 90px;
  border-radius: 9px;
  background-color: black;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.15), 0 3px 9px 0 rgba(0, 0, 0, 0.5);
}

.time {
  padding: 15px 0 0 0;
  font-size: 15px;
  font-weight: 600;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.75);
  color: white;
}