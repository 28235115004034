.component {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  outline: 0;
}

.fileIconPreview {
  display: flex;
  align-self: stretch;
}

.pdfPreview {
  flex-grow: 1;
  align-self: stretch;
}
