
.component {
  position: relative;
  transition: background-size 250ms ease;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  margin: 25px;
  overflow: hidden;

  &.isDropping {
    border-style: dashed;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.15);
  }

  &.isPending:hover {
    background-size: 100% 200%;
  }
}

.fileInput {
  position: absolute;
  top: -9000px;
  left: -9000px;
}

.row {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  flex-direction: row;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 15px 0;
    font-weight: 600;
    flex-shrink: 0;
    color: white;

    .action {
      transition: border-color 250ms ease;
      border: 1px solid rgba(255, 255, 255, 0.15);
      padding: 12px;
      border-radius: 9px;
      font-size: 14px;
      font-weight: 600;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      color: white;
      background: none;
      outline: 0;
      margin-left: auto;
      cursor: pointer;

      &:hover {
        border-color: rgba(255, 255, 255, 0.25);
      }
    }
  }

  .label {
    font-weight: 600;
    flex-shrink: 0;
    padding: 20px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    color: white;
  }

  .input {
    flex-shrink: 1;
    flex-grow: 1;
    background: none;
    border: 0;
    outline: 0;
    padding: 20px;
    font-weight: 400;
    flex-shrink: 0;
    color: white;
  }
}

.filesRow {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;

  .row {
    flex-grow: 1;
    flex-shrink: 1;
  }

  &.isDropping > div {
    pointer-events: none;
  }
}

.files {
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;

  .items {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 12px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 9px;
    overflow: auto;

    .dropping {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.75);
      font-size: 17px;
      font-weight: 700;
      color: white;
      z-index: 1;
    }
  }
}

.prompt {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  cursor: pointer;

  .inner {
    pointer-events: none;
  }

  .title {
    font-size: 32px;
    font-weight: 600;
    color: #FFFFFF;
  }

  .terms {
    padding: 10px 0 0 0;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
  }

  .quota {
    padding: 10px 0 0 0;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.5);
  }

  @media screen and (max-width: 480px) {
    .title {
      font-size: 19px;
    }

    .terms {
      font-size: 14px;
    }

    .quota {
      font-size: 15px;
    }
  }
}

.error {
  margin: 20px 0;
  background: #e4868612;
  padding: 20px;
  border-radius: 6px;
  color: white;
  font-weight: 500;
}

.submit {
  padding: 20px 0 0 0;
  align-items: center;
  border-bottom: 0;

  .warn {
    flex-grow: 1;
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.35);
  }

  .progress {
    position: relative;
    flex-grow: 1;
    align-self: stretch;
    border-radius: 9px;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
    overflow: hidden;

    .text {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 600;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
      color: white;
      z-index: 1;
    }

    .progressInner {
      transition: width 250ms linear;
      background: #FFFFFF;
      width: 0;
      height: 100%;
      opacity: 0.1;
    }
  }

  .upload {
    transition: background-size 250ms ease, opacity 250ms ease;
    flex-grow: 0;
    margin: 0 0 0 20px;
    align-self: flex-end;
    background-image: linear-gradient(180deg, #48952C 0%, #18570E 100%);
    background-size: 100% 100%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
    border-radius: 9px;
    border: 0;
    padding: 12px;
    color: white;
    outline: 0;
    font-weight: 600;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    cursor: pointer;

    &.isUploading {
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
    }

    &:hover {
      background-size: 100% 200%;
    }
  }
}
