.component {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: calc(var(--unit) * 4);
  overflow: hidden;
  z-index: 0;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.05) 25px, rgba(255,255,255,0.05) calc(100% - 25px), rgba(255,255,255,0));
    content: '';
  }
}

.inner {
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  max-width: 800px;
  max-height: 90%;
  flex-direction: column;
  align-items: flex-start;
  background: black;
  box-shadow: 0 4px 32px 0 black;
  padding: 25px;
  text-align: center;
  z-index: 2;
  overflow: hidden;

  .border {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 1;
    padding: 25px;
    text-align: center;
    border: 2px solid #75140C;
    overflow: auto;
  }
}

.scroll {
  padding: 0;
  align-items: stretch;

  .scrollInner {
    transform: translate3d(0,0,0);
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 50px;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    overflow: auto;
    z-index: 2;

    .inner {
      overflow: unset;
      max-width: 980px;
      max-height: unset;

      .border {
        text-align: left;
      }

      .body {
        font-size: 19px;
        line-height: 29px;
      }
    }
  }
}

.art {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;

  .bg {
    object-position: 50% 30% !important;
  }
}

.heading {
  display: inline-block;
  font-weight: 800;
  margin: 0 auto;
  padding: 0 0 25px 0;
  font-size: 36px;
  line-height: 36px;
  border-radius: 8px;
  text-transform: uppercase;
  color: white;
}

.listItem {
  margin: 0;
  font-size: 19px;
  line-height: 29px;
  padding: 0 0 10px 0;
}

.body {
  margin: 0;
  padding: 0;
  font-size: 21px;
  line-height: 32px;

  & em {
    font-style: normal;
    font-weight: 600;
    color: white;
  }

  & a {
    color: white;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom-color: white;
    }
  }

  & i {
    font-size: 17px;
    color: #616161;
    letter-spacing: 0.3ch;
  }
}

.body + .body {
  padding: 25px 0 0 0;
}

@media screen and (max-width: 600px) {
  .scroll .scrollInner, .inner, .border {
    padding: 15px;
  }

  .inner {
    max-height: 95%;
  }

  .body {
    font-size: 19px;
    line-height: 27px;
  }

  .component {
    padding: 15px;

    &.scroll {
      padding: 0;

      .scrollInner .body, .listItem {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
}