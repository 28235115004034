:root {
  --unit: 8px;
  --font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Noto Sans', 'Droid Sans', 'Open Sans', sans-serif;
  --container-border-radius: 0;
  --highlight-color: #00ff97;
}

.component {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  text-align: center;
  background-image: linear-gradient(180deg, #191A1E 0%, #16171A 14%, #000000 100%);
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 800px) {
    height: unset;
    overflow: auto;
  }
}

.portals {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}