.component {
  margin: auto;
  width: 74px;
  text-align: center;
  user-select: none;
}

.icon {
  margin: auto;
  vertical-align: middle;
}