@keyframes popIn {
  0% {
    transform: translate3d(0, 15px, 0) scale(0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
}

.timelineEvent {
  position: relative;
  transform: translate3d(var(--x), -50%, 0);
  position: absolute;
  bottom: 132px;
  right: -15px;
  margin: 0 0 0 -15px;
  cursor: pointer;
  pointer-events: all;

  .dot {
    position: relative;
    display: flex;
    top: 2px;
    width: 30px;
    height: 30px;
    padding: 2px;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    transition: border-color 250ms ease, box-shadow 250ms ease, opacity 250ms ease, transform 250ms ease;
    transform: scale(1);
    background: var(--tag-color, rgba(255, 255, 255, 0.5));
    box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.85);
    overflow: hidden;

    .dotInner {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: black;
      border-radius: 50%;
      overflow: hidden;
    }

    &.hasImage {
      box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.85);

      .dotInner:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        border-radius: 50%;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.25);
      }
    }
  }

  .eventPopover {
    transform: translate3d(-50%, 0, 0);
    position: absolute;
    bottom: 0;
    left: 15px;
    display: flex;
    padding: 0 0 50px 0;
    cursor: default;
    pointer-events: none;

    .inner {
      animation: popIn 350ms ease forwards;
      transform-origin: 50% 100%;
      flex-grow: 1;
      display: flex;
      max-width: min(600px, 80vw);
      min-width: min(500px, 80vw);
        /* padding: 25px; */
      background: #000;
      border-radius: 9px;
      box-shadow: 0 6px 18px 2px rgba(0, 0, 0, 0.5);
      text-align: left;
      cursor: default;
      pointer-events: all;

      &:after {
        transform: translate3d(-50%, 0, 0);
        content: '';
        position: absolute;
        left: 50%;
        bottom: -9px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 9px 8px 0 8px;
        border-color: black transparent transparent transparent;
      }

      .border {
        position: relative;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        border-radius: 9px;
        /* border: 1px solid #75140C; */
        overflow: hidden;
      }

      .split {
        position: relative;
        display: flex;
        padding: 20px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.05));
        flex-direction: row;
        align-items: center;
        border-top: 1px solid rgba(255, 255, 255, 0.05);
        z-index: 2;

        .fullDate {
          display: flex;
          flex-direction: column;
          font-size: 17px;
          font-weight: 700;
          text-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);

          & label {
            padding: 0 0 5px 0;
            font-size: 12px;
            text-transform: uppercase;
            opacity: 0.5;
          }
        }

        .btn {
          margin-left: auto;
        }
      }
    }
  }

  .hoverHelper {
    position: absolute;
    bottom: 0;
    left: -15px;
    width: 60px;
    height: 50px;
  }

  &.isEditing {
    .eventPopover {
      .eventInfo {
        align-self: stretch;
      }
    }
  }

  &:hover, &.isEditing {
    z-index: 1;

    .dot {
      transform: scale(1.1);
      border-color: #75140C;
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 1), 0 3px 6px 2px rgba(0, 0, 0, 0.85);
      opacity: 1 !important;
    }

    .timelineDate {
      color: rgba(255, 255, 255, 1);
      opacity: 1 !important;
    }
  }
}

.timelineDate {
  transform: translate3d(-50%, 0, 0);
  position: absolute;
  top: 100%;
  left: 50%;
  transition: color 250ms ease, opacity 250ms ease;
  padding: 13px 0 0 0;
  font-size: 11px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  white-space: nowrap;
}