
.component {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: row;
  align-self: stretch;
  background: black;
  outline: 0;
  overflow: hidden;

  &:hover, &.isPaused {
    transform: translate3d(0, 0, 0);

    .controls {
      opacity: 1;
    }
  }
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;

  &.isAudio {
    height: 100px;

    &.hasPoster {
      height: 100%;
    }
  }

  @media screen and (max-width: 960px) {
    position: static;
  }
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) 7%, rgba(0, 0, 0, 0) 23%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.5) 82%, rgba(0, 0, 0, 0.5));
  cursor: pointer;
  z-index: 3;

  &.noPointer {
    pointer-events: none;
  }
}

.controls {
  transition: opacity 250ms ease;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  /*overflow: hidden;*/
  color: white;
  opacity: 0;
  user-select: none;
  z-index: 4;
}

.time {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 25px;

  .timeCode {
    font-size: 12px;
    font-variant-numeric: tabular-nums;
    color: rgba(255, 255, 255, 0.5);
  }

  .progressContainer {
    position: relative;
    display: flex;
    flex-grow: 1;
    padding: 0 10px;
  }
}

.range {
  --progress: 0%;
  appearance: none;
  display: block;
  flex-grow: 1;
  flex-shrink: 0;
  display: block;
  width: 100%;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;

  &.disabled {
    cursor: default;
    pointer-events: none;
  }

  &::-webkit-slider-runnable-track {
    transition: none;
    appearance: none;
    height: 16px;
    display: block;
    color: transparent;
    border-color: transparent;
    border-radius: 3px;
    background-color: transparent;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) var(--progress), rgba(235, 235, 245, 0.3) var(--progress));
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 3px;
    outline: 0;
    border: 0;
    cursor: pointer;
    outline: 0;
  }

  &::-moz-range-track {
    transition: none;
    appearance: none;
    height: 16px;
    display: block;
    color: transparent;
    border-color: transparent;
    border-radius: 3px;
    background-color: transparent;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) var(--progress), rgba(235, 235, 245, 0.3) var(--progress));
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 3px;
    outline: 0;
    border: 0;
    cursor: pointer;
    outline: 0;
  }

  &::-webkit-slider-thumb {
    transition: none;
    appearance: none;
    width: 9px;
    height: 9px;
    outline: 0;
    border: 0 solid transparent;
    border-radius: 50%;
    background-color:  white !important;
    background-position: center center;
    outline: 1px solid transparent;
    cursor: pointer;
    margin-top: 3.5px;
  }

  &::-moz-range-thumb {
    transition: none;
    appearance: none;
    width: 9px;
    height: 9px;
    outline: 0;
    border: 0 solid transparent;
    border-radius: 50%;
    background-color:  white !important;
    background-position: center center;
    outline: 1px solid transparent;
    cursor: pointer;
    margin-top: 3.5px;
  }

  &::-ms-thumb {
    transition: none;
    appearance: none;
    width: 9px;
    height: 9px;
    outline: 0;
    border: 0 solid transparent;
    border-radius: 50%;
    background-color:  white !important;
    background-position: center center;
    outline: 1px solid transparent;
    cursor: pointer;
    margin-top: 3.5px;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
  padding: 5px 10px 5px 25px;

  .actionCol {
    flex-basis: 33.3%;
  }

  .center {
    display: flex;
    flex-direction: row;
    padding: 0 15px;
    align-items: center;
    justify-content: center;
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

.volumeContainer {
  display: flex;
  flex-grow: 0;
  width: 60px;
}

.button {
  transition: opacity 250ms ease;
  appearance: none;
  background: none;
  border: 0;
  outline: 0;
  padding: 15px;
  color: white;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.circleButton {
  transition: background 250ms ease;
  transition-delay: 50ms;
  padding: 12px;
  background: transparent;
  border-radius: 30px;
  border: 0;
  color: white;
  cursor: pointer;

  & svg {
    transition: transform 250ms ease;
    vertical-align: middle;
    transform-origin: 50% 50%;
  }

  &:active {
    background: rgba(255, 255, 255, 0.1);

    & svg {
      transform: scale(0.9);
    }
  }
}