.component {
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  outline: 0;
  cursor: pointer;
}

.image {
  flex-grow: 0;
  max-width: 100%;
  max-height: 100%;
}

.expand {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  z-index: 1;
}

.isHighlighting {
  .image {
    position: relative;
    border: 1px solid red;
    z-index: 2;
  }
}

.highlightBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1;
}