.authTrigger {
  transition: opacity 250ms ease;
  appearance: none;
  background: none;
  border: 0;
  outline: 0;
  opacity: 0.25;
  padding: 25px;
  font-size: 24px;
  pointer-events: all;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
    transform: rotate(180deg) scale(1.25);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}

.authWorking {
  animation: rotate 1s linear infinite;
  cursor: default;
  opacity: 1;
}

.discordAuthHandler {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  padding: 25px;
  background: black;
}

.avatar {
  position: relative;
  background: black;
  border-radius: 50%;
}