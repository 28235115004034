.component {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  flex-direction: row;
  padding: calc(var(--unit) * 3) calc(var(--unit) * 6);
  column-gap: calc(var(--unit) * 4);
  // overflow: hidden;
  z-index: 1;
}

.middle {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  align-self: stretch;
  gap: calc(var(--unit) * 2);
}

.splitter {
  display: flex;
  flex: 0 0 auto;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 1px;
  background-color: rgba(255,255,255,0.15);
  z-index: 1;
}

.right {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: flex-end;
  gap: calc(var(--unit) * 2);
}

.logoWrapper {
  position: relative;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  z-index: 1;

  .logo {
    width: 176px;
    flex-shrink: 0;
    flex-grow: 0;
    color: white;
    vertical-align: middle;
  }
 
  .user {
    padding: 0 0 5px 0;
    font-style: italic;
    color: rgba(255, 255, 255, 0.5);

    .username {
      border-bottom: 1px solid #ffecd485;
      font-weight: 600;
      color: #ffecd4;
    }
  }

  .intro {
    margin: 0;
    padding: 0;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.1px;
    font-style: italic;
    text-align: right;
    color: rgba(255, 255, 255, 0.25);
  }

  @media screen and (max-width: 600px) {
    justify-content: center;

    .right {
      display: none;
    }
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: auto;
  gap: calc(var(--unit) * 2);
  z-index: 1;

  .tab {
    transition: border-color 250ms ease, color 250ms ease;
    flex-grow: 1;
    padding: calc(var(--unit)) calc(var(--unit) * 1.5);
    border: 2px solid transparent;
    border-radius: 9px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.75);
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;

    .newBadge {
      color: black;
      font-size: 12px;
      background: var(--highlight-color);
      border-radius: 3px;
      padding: 2px 4px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border-color: rgba(255, 255, 255, 0.35);
    }

    // &.timeline {
    //   color: var(--highlight-color);
    //   --highlight-color: #de5045;
    // }

    // &.createParty {
    //   color: var(--highlight-color);
    //   --highlight-color: rgb(255, 212, 147);;
    // }

    &.tabActive {
      /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1)); */
      /* box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.25); */
      border: 2px solid var(--highlight-color);
      border-radius: 9px;
      color: var(--highlight-color);
    }
  }

  // @media screen and (max-width: 1235px) {
  //   .tab {
  //     display: none;

  //     &.share, &.all {
  //       display: flex;
  //       flex-grow: 1;
  //       align-items: center;
  //       justify-content: center;
  //     }
  //   }
  // }
}
