.component {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  z-index: 1000;
}

.inner {
  display: flex;
  width: 600px;
  max-height: 80vh;
  cursor: default;
  overflow: hidden;
}
