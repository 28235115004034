@keyframes animateDot1 {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}

@keyframes animateDot2 {
  0% {
    opacity: 0.8;
  }

  25% {
    opacity: 0.3;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0.8;
  }
}

@keyframes animateDot3 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

.component {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.dot {
  float: left;
  width: 9px;
  height: 9px;
  border-radius: 9px;
  background: #ffffff;
  margin-right: 6px;
}

.dotThree {
  margin-right: 0 !important;
}

.isAnimated {
  .dotOne {
    animation: animateDot1 0.7s infinite;
  }

  .dotTwo {
    animation: animateDot2 0.7s infinite;
  }

  .dotThree {
    animation: animateDot3 0.7s infinite;
  }
}
