.commentsContainer {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  overflow: hidden;
}

.moreMessages {
  transition: transform 250ms ease, opacity 250ms ease;
  transform: translate3d(-50%, 0, 0);
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  background: linear-gradient(to bottom, #2f2d33, #131315);
  width: auto;
  height: auto;
  left: 50%;
  top: 100%;
  opacity: 0;
  padding: 15px;
  border-radius: 9px;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 3px 12px 4px rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  backface-visibility: hidden;
  z-index: 3;

  &.hasShadow {
    background: linear-gradient(to bottom, rgba(0,0,0,0), black);

    &.hasColor {
      background: linear-gradient(to bottom, rgba(11, 11, 19, 0), rgb(11, 11, 19));
    }
  }

  &.isActive {
    transform: translate3d(-50%, -125%, 0);
    opacity: 0.65;

    &:hover {
      opacity: 1;
    }
  }
}