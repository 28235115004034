.component {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  background: linear-gradient(to bottom, #202024, #252428);
  overflow: hidden;
}

.art {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  pointer-events: none;
  mix-blend-mode: color-dodge;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    box-shadow: inset 0 0 0 1px rgba(255,255,255,0.1);
    border-radius: var(--container-border-radius);
    z-index: 1;
  }
}

.overflow {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: auto;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    box-shadow: inset 0 0 0 1px rgba(255,255,255,0.1);
    border-radius: var(--container-border-radius);
    z-index: 1;
  }
}

.inner {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: calc(var(--unit) * 4);
  gap: calc(var(--unit) * 4);
  z-index: 1;
}

.heading {
  padding: calc(var(--unit) * 3);
  font-size: 19px;
  font-weight: 500;
  text-shadow: 0 1px 3px rgba(0,0,0,0.50);
  line-height: 24px;
  background: linear-gradient(to bottom, rgba(0,0,0,0.65), rgba(0,0,0,0.45));
  box-shadow: 0 0 0 1px rgba(255,255,255,0.1);
  color: white;
}

.fieldSet {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  background: rgba(255,255,255,0.05);
  box-shadow: 0 4px 32px 0 rgb(0 0 0 / 50%), inset 1px 1px 0 0 rgba(255,255,255,0.1);
  border-radius: 9px;
  z-index: 1;

  > .row {
    display: flex;
    flex-direction: row;
    box-shadow: inset 0 1px 0 0 rgba(255,255,255,0.05), inset 0 -1px 0 0 rgba(0,0,0,0.25);

    > .field {
      box-shadow: 1px 0 0 0 rgba(0,0,0,0.25), 2px 0 0 0 rgba(255,255,255,0.05);

      // &:last-child {
      //   box-shadow: none;
      // }
    }

    .row {
      display: flex;
      flex-direction: row;
      gap: calc(var(--unit) * 2);
    }

    .input {
      align-self: stretch;
      height: 54px;
    }

    &:first-child {
      // No top border  for the first row
      box-shadow: none;
    }

    @media screen and (max-width: 984px) {
      flex-direction: column;

      > .field {
        box-shadow: inset 0 2px 0 0 rgba(255,255,255,0.05), inset 0 1px 0 0 rgba(0,0,0,0.25);
        z-index: 2;

        &:first-child {
          box-shadow: none;
        }
      }
    }
  }
}

.cover {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 25px 0 0;

  .preview {
    display: inline-grid;
    flex: 1 1 auto;
    background: rgb(3, 3, 3);
    border-radius: 9px;
    background-size: cover;
    background-position: 50% 50%;
    box-shadow: inset 0 0 0 1px rgba(0,0,0,0.8), inset 0 0 0 2px rgba(255,255,255,0.1), rgb(0 0 0 / 10%) 0px 26px 30px -10px, rgb(0 0 0 / 20%) 0px 16px 10px -10px;
    overflow: hidden;

    .ratio {
      height: 100%;
      opacity: 0;
    }
  }

  .input {
    margin: 35px 0 0 0;
  }
}

.field {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: calc(var(--unit) * 3);
  gap: calc(var(--unit) * 2);
  align-items: flex-start;
  text-align: left;

  .label {
    font-weight: 600;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 1);
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);

    & em {
      font-style: normal;
      opacity: 0.35;
    }
  }

  &.date {
    flex: 0 0 auto;
  }

  &.cover {
    flex-grow: 1;
    align-items: stretch;
    text-align: left;

    .row {
      flex-grow: 1;
      position: relative;
    }

    .browserTrigger {
      transition: opacity 250ms ease;
      transform: translate3d(0, -50%, 0);
      position: absolute;
      top: 50%;
      right: 1ch;
      background: rgba(0, 0, 0, 1);
      padding: calc(var(--unit) * 1.25);
      font-size: 14px;
      font-weight: 600;
      border-radius: 8px;
      color: white;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    .input {
      flex-grow: 1;
      margin: 0;
      padding-right: calc(2ch + 190px);
      text-overflow: ellipsis;
    }
  }
}

.configure {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row;
  overflow: hidden;

  .about {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: hidden;
  }

}

.error {
  display: flex;
  padding: 20px;
  margin: 0 35px 25px;
  background: #ab4d4d;
  border-radius: 9px;
  font-weight: 600;
}

.tracks {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  z-index: 1;

  .field {
    flex-direction: row;
  }

  .table {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    text-align: left;
    background: rgba(255,255,255,0.05);
    // backdrop-filter: blur(10px);
    box-shadow: 0 4px 32px 0 rgb(0 0 0 / 50%), inset 1px 1px 0 0 rgba(255,255,255,0.1);
      border-radius: 9px;
    overflow: hidden;

    .header {
      display: flex;
      flex-direction: row;
      box-shadow: inset 0 -1px 0 0 rgba(255,255,255,0.05), 0 1px 0 0 rgba(0,0,0,0.25), 0 2px 8px 0 rgb(0 0 0 / 15%);
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      pointer-events: none;
    }

    .empty {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      justify-content: center;
      padding: calc(var(--unit) * 3);
      // background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
      background-color: rgba(0,0,0,0.25);
      font-size: 17px;
      font-weight: 500;
      color: white;
    }

    .list {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      z-index: 1;
    }
  }

  .footer {
    display: flex;

    .button {
      transition: background-color 250ms ease;
      flex-grow: 1;
      align-self: flex-end;
      padding: calc(var(--unit) * 2);
      background-color: rgba(255,255,255,0.05);
      border: 0;
      font-size: 17px;
      font-weight: 600;
      color: white;
      outline: 0;
      cursor: pointer;

      &.disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.5;
      }

      &:hover {
        background-color: rgba(255,255,255,0.1);
      }
    }
  }
}

.column {
  padding: calc(var(--unit) * 1.75);
}

.handle {
  flex-grow: 0;
  flex-shrink: 0;
  width: 36px;
  display: flex;
  padding: 0 0 0 calc(var(--unit) * 1.75);
  align-items: center;
  justify-content: center;
  cursor: ns-resize;
  overflow: hidden;

  .grip {
    width: 100%;
    height: 18px;
    position: relative;
    opacity: 0.25;
  }

  .l {
    position: absolute;
    width: 100%;
    height: 2px;
    background: white;
    border-radius: 2px;
    overflow: hidden;

    &:nth-child(2) {
      transform: translate3d(0, -50%, 0);
      top: 50%;
    }

    &:nth-child(3) {
      bottom: 0;
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.inputWrapper {
  position: relative;
  display: flex;
}

.input {
  transition: border-color 250ms ease, background 250ms ease;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.025);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 12px;
  outline: 0;
  font-size: 19px;
  color: white;

  &:invalid {
    border-color: #75140C;
    background-color: rgba(117, 20, 12, 0.2);
  }

  &:focus {
    border-color: rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.15);
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

.create {
  transition: background-size 250ms ease, opacity 250ms ease;
  flex-grow: 0;
  align-self: flex-end;
  background-image: linear-gradient(180deg, #48952C 0%, #18570E 100%);
  background-size: 100% 100%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
  border-radius: 9px;
  border: 0;
  padding: 12px;
  color: white;
  outline: 0;
  font-size: 19px;
  font-weight: 600;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  cursor: pointer;

  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background-size: 100% 200%;
  }
}

.browser {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  z-index: 1000;

  .inner {
    display: flex;
    width: 600px;
    max-height: 80vh;
    cursor: default;
    overflow: hidden;
  }
}

// This needs to be in the global scope for the drag ghost to work
.file {
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: rgba(0,0,0,0.15);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
  font-weight: 600;
  color: white;

  &.helper {
    margin: calc(var(--unit) * -1);
    padding: calc(var(--unit) * 1);
    border-radius: 9px;
    background: rgba(255,255,255,0.05);
    // backdrop-filter: blur(10px);
    box-shadow: 0 4px 32px 0 rgb(0 0 0 / 50%), inset 1px 1px 0 0 rgba(255,255,255,0.1);
    z-index: 1000;
  }

  .remove {
    transition: opacity 250ms ease;
    margin-left: auto;
    padding: 0 15px;
    opacity: 0;
    cursor: pointer;

    & svg {
      width: 21px;
    }
  }

  &:hover {
    .remove {
      opacity: 0.25;

      &:hover {
        opacity: 1;
      }
    }
  }
}
