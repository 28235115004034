@import "./normalize.css";

:global * {
  box-sizing: border-box;
  /* user-select: none; */
  -moz-box-sizing: border-box;
  /* -webkit-user-select:none; */
  -webkit-user-drag: none;
  -webkit-user-modify: none;
  -webkit-user-callout: none;
  -webkit-highlight: none;
}

:global html, :global body, :global #root {
  height: 100%;
  min-height: 100%;
  width: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Noto Sans', 'Droid Sans', 'Open Sans', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;  
}

:global body {
  background: black;
}

@font-face {
  font-family: 'awk-narrow';
  src: url(~public/fonts/gotham-narrow-bold.woff) format('woff2'),
      url(~public/fonts/gotham-narrow-bold.woff2) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}