
.component {
  position: relative;
  display: flex;
  align-self: stretch;
  overflow: hidden;
}

.contents {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
}

.downloadHint {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 25px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75) 35%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.9));
  text-align: center;
  z-index: 2;

  .hint {
    font-size: 21px;
    font-weight: 600;
    padding: 0 0 25px 0;
  }

  .actions {
    display: flex;
    flex-direction: row;
    pointer-events: all;
    background: black;
    border-radius: 9px;
  }
}
