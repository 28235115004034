.component {
  transition: background 250ms ease, background-size 250ms ease, box-shadow 250ms ease, opacity 250ms ease;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  // background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
  // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0), inset 0 2px 4px 0 rgba(0, 0, 0, 0), 0 3px 6px 0px rgba(0, 0, 0, 0.25);
  // background-size: 100% 100%;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.15);
  border: 0;
  padding: calc(var(--unit) * 1.5);
  border-radius: 9px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  opacity: 0.75;
  cursor: pointer;
  user-select: none;
  outline: 0;

  /*
  span + input, span + svg {
    margin: 0 0 0 8px;
  }

  input + span, svg + span {
    margin: 0 0 0 8px;
  }
  */

  &:hover:not(.isWorking):not(.disabled), &:focus:not(.isWorking):not(.disabled) {
    background-size: 100% 200%;
    opacity: 1;
  }

  &:active:not(.isWorking) {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25), inset 0 2px 4px 0 rgba(0, 0, 0, .5), 0 3px 6px 0px rgba(0, 0, 0, 0.25);
  }
}

.green {
  // background-image: linear-gradient(180deg, #48952C 0%, #18570E 100%);
  box-shadow: inset 0 0 0 2px var(--highlight-color);
  color: var(--highlight-color);
}

.etched {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: inset 0 1px 0 0 black, inset 0 -1px 0 0 rgba(255, 255, 255, 0.2);
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.85);
  opacity: 0.5;

  &:hover:not(.isWorking):not(.disabled), &:focus:not(.isWorking):not(.disabled) {
    opacity: 1;
  }

  &:active:not(.isWorking) {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 0 0 black, inset 0 -1px 0 0 rgba(255, 255, 255, 0.2);
    opacity: 1;
  }
}

.sizeMedium {
  // border-radius: 9px;
  padding: calc(var(--unit) * 1.75);
  font-size: 17px;
  // padding: 12px;
}

.disabled {
  cursor: default;
  opacity: 0.5;
}

.isWorking {
  cursor: wait;
}

.set {
  display: contents;
}

@media screen and (min-width: 960px) {
  .component + .component {
    margin: 0 0 0 12px;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .component:not(.hideMedium) + .component:not(.hideMedium) {
    margin: 0 0 0 12px;
  }

  .hideMedium {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .component:not(.hideSmall) + .component:not(.hideSmall) {
    margin: 0 0 0 12px;
  }

  .hasIconAndLabel {
    .icon {
      padding: 0 4px;
    }
    .label {
      display: none;
    }
  }

  .hideAtMedium {
    display: none;
  }

  .hideAtSmall {
    display: none;
  }
}
