.component {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 9px;
  box-shadow: inset 0 0 0 1.5px rgba(255, 255, 255, 0.15),
              0 0 0 1px rgba(0, 0, 0, 0.15),
              0 3px 12px 4px rgb(0, 0, 0, 0.25);
  background-color: black;
  background-position: 50% 50%;
  background-size: cover;
  overflow: hidden;
  width: 44px;
  height: 44px;
}
