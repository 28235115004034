.component {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  min-height: 38px;
  z-index: 2;
}

.inner {
  transition: background-color 250ms ease;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(255,255,255,0.05);
  box-shadow: 0 8px 24px 0 rgba(0,0,0,0.50);
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  overflow: hidden;
  cursor: pointer;
  text-align: left;
  z-index: 0;

  .prefix {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: 0 calc(var(--unit) * 1.5);
    background-image: linear-gradient(to bottom,  rgba(255,255,255,0.1), transparent);
    border-left: 1px solid rgba(255,255,255,0.1);
  }

  .label {
    flex: 1 1 auto;
    justify-content: flex-start;
    padding: 0 calc(var(--unit) * 1.5);
    white-space: nowrap;
  }

  &:hover {
    background-color: rgba(255,255,255,0.075);
  }
}

.input {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;
  min-height: 38px;
  padding: calc(var(--unit)) calc(var(--unit) * 1.5);
  background: none;
  border: 0;
  outline: 0;
  font-size: 15px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: white;

  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.floating {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(0,0,0,1);
  box-shadow: 0 0 0 1px var(--highlight-color), 0 8px 24px 8px rgba(0,0,0,1);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1;
}

.searchType {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: calc(var(--unit) * 1.5);
  padding: var(--unit) calc(var(--unit) * 2);
  border-top: 1px solid rgba(255,255,255,0.1);
  background: linear-gradient(to bottom, #171719, #0f0f10);
  pointer-events: all;

  .searchTypeLabel {
    font-size: 14px;
    font-weight: 600;
  }

  .searchTypeButtons {
    display: flex;
    flex-direction: row;
    gap: calc(var(--unit) * 1.5);
  }

  .searchTypeButton {
    transition: background-color 250ms ease, box-shadow 250ms ease, color 250ms ease;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: calc(var(--unit) * 1) calc(var(--unit) * 1.5);
    justify-content: center;
    background: none;
    border-radius: 30px;
    border: 0;
    font-size: 14px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
    overflow: hidden;
    cursor: pointer;
    white-space: pre-wrap;
    text-decoration: none;

    .icon {
      margin-left: 0.5ch;
    }

    &.isSelected, &:hover {
      background-color: rgba(255, 255, 255, 0.05);
      box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.1);
      color: #ffffff;
    }
  }
}

.results, .loading {
  flex-direction: column;
  border-top: 1px solid rgba(255,255,255,0.1);
  max-height: 500px;
  overflow: auto;
  background-color: rgba(0,0,0,0.75);
}

.hasList {
  .result {
    --x-padding: 0px;
    border-bottom: 1px solid rgba(255,255,255,0.1);

    &:last-child {
      border-bottom: 0;
    }
  }
}

.hasCards {
  display: grid;
  width: 100%;
  // two columns
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: max-content;
  gap: calc(var(--unit) * 2) calc(var(--unit) * 2);
  padding: calc(var(--unit) * 2);
  text-align: left;

  .result {
    display: contents;
  }
}

.loading {
  padding: calc(var(--unit)) calc(var(--unit) * 1.5);
}

.empty {
  padding: calc(var(--unit) * 2);
  font-weight: 500;
}