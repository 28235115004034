
.component {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  min-width:0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding: calc(var(--unit) * 2);
  font-size: 14px;

  &:last-child {
    border-bottom: 0;
  }
}

.link {
  transition: background 250ms ease;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  text-decoration: none;
  align-items: center;
  padding: 10px;
  background: rgba(255, 255, 255, 0.0);
  border-radius: 8px;
  overflow: hidden;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
}

.icon {
  margin: 0 10px 0 0;
  vertical-align: middle;
}

.title {
  font-weight: 600;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
}

.backup {
  margin: 0 0 0 10px;
  flex-shrink: 0;
  flex-grow: 0;
}

.remove {
  transition: opacity 250ms ease;
  margin: 0 0 0 10px;
  padding: 5px;
  opacity: 0.2;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}