.leafs {
  flex: 1 1 auto;
  text-align: left;
  overflow: auto;

  &.addHeaderPadding {
    padding-top: 50px;
  }
}

.loading {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
}

.tableHeader {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  text-align: left;
  text-decoration: none;
  overflow: hidden;
  padding: 0 calc(var(--unit) * 4);
  height: calc(var(--unit) * 7);
  align-items: center;
  gap: calc(var(--unit) * 2);
  z-index: 1;
  user-select: none;

  &.sortable {
    .name, .date, .views {
      cursor: pointer;
    }
  }

  &:last-child {
    border-bottom: 0;
  }

  & span {
    vertical-align: middle;
  }

  .iconCell {
    flex: 0 0 auto;
    vertical-align: middle;
    text-align: center;

    &.canToggle {
      transition: opacity 250ms ease;
      opacity: 0.5;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    & svg {
      vertical-align: middle;
    }

    .fileIcon {
      width: inherit;
      height: inherit;
    }

    .icon {
      display: flex;
      width: 24px;
      flex-shrink: 0;
      flex-grow: 0;
      align-items: center;
      justify-content: center;
      margin: auto;
      color: white;
      text-align: left;
    }
  }

  .name, .date, .views {
    transition: opacity 250ms ease;

    & svg {
      margin: 0 0 0 5px;
      vertical-align: middle;
    }

    &:hover, &.isActive {
      opacity: 1;
    }
  }

  .name {
    line-height: 21px;
    opacity: 0.5;
  }

  .ocr, .comments {
    transition: opacity 250ms ease;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 auto;
    padding: 0 0 0 24px;
    font-size: 14px;
    font-weight: 500;
    font-variant: tabular-nums;
    color: white;
    opacity: 0.5;
    vertical-align: middle;

    .enabled {
      border: 1px solid rgba(255, 255, 255, 0.35);
      border-radius: 4px;
      font-size: 12px;
      padding: 4px;
    }

    .commentsCount {
      padding: 0 0 0 5px;
    }
  }

  .typeCount {
    transition: opacity 250ms ease;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: center;
    border-radius: 30px;
    padding: 6px 8px;
    font-size: 12px;
    font-weight: 600;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    color: white;
    white-space: pre-wrap;
    opacity: 0.5;
    vertical-align: middle;

    @media screen and (max-width: 600px) {
      .type {
        display: none;
      }
    }

    &.typeCountIMAGE {
      background: linear-gradient(to bottom, #0e4edc, #3690c5);
    }

    &.typeCountVIDEO {
      background: linear-gradient(to bottom, #6b0c64, #de4c35);
    }

    &.typeCountAUDIO {
      background: linear-gradient(to bottom, #6b4e0c, #dea235);
    }

    &.typeCountUNKNOWN {
      background: linear-gradient(to bottom, #525252, #797979);
    }
  }

  .date, .views {
    transition: opacity 250ms ease;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    padding: 0 12px;
    font-size: 12px;
    font-weight: 700;
    font-variant: tabular-nums;
    text-align: left;
    text-transform: uppercase;
    line-height: 17px;
    white-space: nowrap;
    color: white;
    opacity: 0.5;
    vertical-align: middle;
  }

  .date {
    width: 150px;
  }

  .views {
    padding-right: 0;
    width: 75px;
  }

  @media screen and (max-width: 600px) {
    // .iconCell {
    //   padding-left: 12px;
    // }

    .date, .views {
      display: none;
    }
  }
}

.tableHeaderOuter {
  flex-grow: 0;
  flex-shrink: 0;
  // box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 32px 0 black;
  z-index: 1;

  &.fixedHeader {
    position: absolute;
    top: 0;
    width: 100%;
    background: #111215;
  }
}

.tableHeaderInner {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
}

.name {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: white;
  overflow: hidden;
  vertical-align: middle;

  .fileName {
    flex-grow: 0;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .suffix {
    flex-shrink: 0;
    flex-grow: 0;
  }
}

.tabs {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  gap: var(--unit);
  margin-left: calc(var(--unit) * -0.5);
  overflow: auto;

  .tab {
    transition: background-color 250ms ease, box-shadow 250ms ease, color 250ms ease;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: calc(var(--unit) * 1.25) calc(var(--unit) * 2);
    justify-content: center;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
    overflow: hidden;
    cursor: pointer;
    white-space: pre-wrap;
    text-decoration: none;

    .icon {
      margin-left: 0.5ch;
    }

    &.isActive, &:hover {
      background-color: rgba(255, 255, 255, 0.05);
      box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.1);
      color: #ffffff;
    }
  }
}

.hasCards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: calc(var(--unit) * 4);
  grid-auto-rows: max-content;
  align-items: flex-start;
  padding: calc(var(--unit) * 2) calc(var(--unit) * 4) 0;

  @media screen and (max-width: 1800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  
  @media screen and (max-width: 1360px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  
  @media screen and (max-width: 980px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
  
  &.smallContainer {
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 1900px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 1360px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 980px) {
      grid-template-columns: 1fr 1fr;
    }
  }
}


.collectionPreview {
  transition: background-size 250ms ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  background-size: 100% 100%;
  padding: 15px;
  z-index: 2;

  .count {
    padding: 7px 9px;
    font-size: 12px;
    font-weight: 600;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    color: white;
    z-index: 1;
  }

  .gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.25));
  }
}