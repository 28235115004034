.component {
  transition: background-color 250ms ease, transform 250ms ease;
  transform-origin: 50% 0;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  // will-change: transform;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    // transform: scale(1.025);

    .preview {
      &:before {
        box-shadow: inset 0 0 0 2px rgba(255,255,255,0.25);
      }

      > .imagePreview, > .fileIcon {
        transform: scale(1.1);
        opacity: 1 !important;
      }

      .stats .stat, .stats .duration {
        background: rgba(0, 0, 0, 0.5);
        border-color: rgba(0, 0, 0, 0.35);
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.35);
        opacity: 1;
      }

      .collectionPreview {
        background-size: 250% 100% !important;
        opacity: 1;
      }

      .play {
        color: rgba(255, 255, 255, 0.75);
      }
    }
  }
}

.border {
  --radius: 9px;
  transition: background-color 250ms ease;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  border-radius: var(--radius);
  text-decoration: none;

  .preview {
    transform: translate3d(0, 0, 0);
    position: relative;
    height: 160px;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 9px;
    background-color: black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.35);
    overflow: hidden;

    &:before {
      transition: box-shadow 250ms ease;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 1px 1px 1px 0 rgba(255,255,255,0.15);
      border-radius: var(--radius);
      transform: translate3d(0,0,0);
      z-index: 3;
    }

    .stats {
      position: absolute;
      top: 15px;
      right: 15px;
      display: flex;
      flex-direction: row;
      z-index: 3;

      .ocr, .comments {
        transition: opacity 250ms ease;
        display: flex;
        flex-direction: row;
        flex-grow: 0;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 auto;
        padding: 0 0 0 24px;
        font-size: 14px;
        font-weight: 500;
        font-variant: tabular-nums;
        color: white;
        opacity: 0.5;
        vertical-align: middle;

        .enabled {
          border: 1px solid rgba(255, 255, 255, 0.35);
          border-radius: 4px;
          font-size: 12px;
          padding: 4px;
        }

        .commentsCount {
          padding: 0 0 0 5px;
        }
      }

      .duration {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
        font-size: 11px;
        line-height: 11px;
        font-weight: 600;

        &:hover {
          background: rgba(0, 0, 0, 0.75);
          border-color: rgba(0, 0, 0, 0.35);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.35);
          opacity: 1;
        }

        &.isPlaying {
          background: rgba(0, 0, 0, 0.75);
          border-color: rgba(0, 0, 0, 0.35);
          box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.35), 0 2px 6px 0 rgba(0, 0, 0, 0.35);
          opacity: 1;/*
          background: rgba(255, 255, 255, 0.2);
          box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.35);
          font-weight: 700;
          opacity: 1;*/
        }
      }
    }

    .play {
      transition: color 250ms ease;
      transform: translate3d(-50%, -50%, 0);
      position: absolute;
      top: 50%;
      left: 50%;
      filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.75));
      color: rgba(255, 255, 255, 0.25);
      z-index: 2;
    }

    .imagePreview, .fileIcon, .collectionPreview {
      transition: all 250ms ease;
    }
  }

  .info {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    align-items: center;
    gap: calc(var(--unit) * 2);
    padding: calc(var(--unit) * 2) 0;
    // background: linear-gradient(to bottom, rgb(27 28 31), rgb(41 44 47));
    background-size: 100% 100%;
    border-radius: 0 0 9px 9px;
    // overflow: hidden;

    .vStack {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .type {
      flex: 0 0 auto;
      margin: 0;
      width: 24px;
      height: 24px;
      opacity: 0.5;
    }

    .name {
      color: #FFF;
      font-size: 17px;
      font-weight: 600;
      text-decoration: none;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    }

    .meta {
      display: block;
      padding: 6px 0 0 0;
      font-size: 12px;
      font-weight: 500;
      white-space: nowrap;
      color: rgba(255, 255, 255, 0.5);
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}


.name {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: white;
  overflow: hidden;
  vertical-align: middle;

  .fileName {
    flex-grow: 0;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .suffix {
    flex-shrink: 0;
    flex-grow: 0;
  }
}

.stacks {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;

  .stack {
    width: 90%;
    height: 5px;
    border-radius: 0 0 9px 9px;
    background-color: rgba(255, 240, 240, 0.073);
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-top: 0;

    &:nth-child(2) {
      width: 80%;
    }
  }
}
