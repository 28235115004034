
.component {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row;
  padding: 15px 0 0 0;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  overflow: hidden;
}

.right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 0 0 15px;
  overflow: hidden;
}

.user {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 0 15px 0;

  &.inlineTrip {
    flex-direction: row;

    .trip {
      margin-top: 0;
    }
  }
}

.trip {
  position: relative;
  display: flex;
  padding: 0;
  margin: 10px 0 0 0;

  .input {
    margin: 0;
    padding: 12px 39px 12px 12px;
  }

  .explain {
    transform: translate3d(0, -50%, 0);
    right: 12px;
    top: 50%;
    position: absolute;

    .btn {
      transition: background 250ms ease;
      position: relative;
      display: flex;
      width: 21px;
      height: 21px;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 700;
      box-shadow: inset 0 0 1px 0 rgba(255, 255, 255, 0.0);
      background: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      cursor: default;
      z-index: 1;

      &:hover {
        background: rgba(255, 255, 255, 0.25);
      }
    }

    .popover {
      transition: opacity 250ms ease, transform 250ms ease;
      transform: translate3d(0, 15px, 0);
      position: absolute;
      /* bottom: 40px; */
      top: -15px;
      right: -15px;
      width: 290px;
      padding: 12px 42px 12px 12px;
      font-size: 14px;
      line-height: 19px;
      border-radius: 9px;
      box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.5);
      background: black;
      color: white;
      opacity: 0;
      pointer-events: none;
    }

    &:hover .popover {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

.input {
  font-size: 13px;
}

.input, .textarea {
  flex-grow: 1;
  background: none;
  padding: 12px;
  height: 44px;
  border-radius: 6px;
  font-weight: 500;
  text-overflow: ellipsis;
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: white;
  outline: 0;

  &:focus {
    border-color: rgba(255, 255, 255, 0.35);
  }
}

.textarea {
  font-size: 15px;
  resize: vertical;

  &:focus, &.focused {
    height: unset;
    min-height: 100px;
  }
}

.actions {
  display: flex;
  padding: 15px 0 0 0;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.submit {
  transition: background-size 250ms ease, opacity 250ms ease;
  display: flex;
  height: 42px;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(180deg, #48952C 0%, #18570E 100%);
  background-size: 100% 100%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
  border-radius: 9px;
  border: 0;
  padding: 12px;
  color: white;
  outline: 0;
  font-size: 14px;
  font-weight: 600;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  cursor: pointer;

  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background-size: 100% 200%;
  }
}