.component {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  z-index: 1;

  @media screen and (max-width: 800px) {
    width: 100vw;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}

.router {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: calc(var(--unit) * 6) calc(var(--unit) * 3) 0;
  margin-top: calc(var(--unit) * -6);
  overflow: hidden;
}

.popover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: calc(var(--unit) * 6);
  background-color: rgb(43 43 48 / 75%);
  z-index: 1;

  .popoverInner {
    --container-border-radius: 19px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    background-color: black;
    box-shadow: 0 0 36px 18px rgba(0,0,0,0.50);
    border-radius: 19px;
    overflow: hidden;
  }
}

.footer {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 0;
  height: 65px;
  align-items: center;
  padding: 0 calc(var(--unit) * 8);

  .longLiveTheParty {
    flex-grow: 1;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
    color: rgba(255, 255, 255, 0.5);
    z-index: 0;
    pointer-events: none;

    & span {
      white-space: nowrap;
    }

    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      line-height: 15px;
    }
  }

  .links {
    display: block;
    margin-left: auto;
    text-align: right;
    white-space: pre-wrap;
    color: rgba(255, 255, 255, 0.25);

    .hideAtMedium {
      @media screen and (max-width: 980px) {
        display: none;
      }
    }

    .hideAtSmall {
      @media screen and (max-width: 650px) {
        display: none;
      }
    }
  }

  .contact {
    transition: border-bottom-color 250ms ease, opacity 250ms ease;
    font-size: 17px;
    font-weight: 600;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.25);
    border-bottom: 1px solid transparent;

    &:hover, &.active {
      color: white;
      border-bottom-color: white;
    }
  }
}