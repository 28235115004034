.component {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 0;
  overflow: hidden;
}

.header {
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.75);

  .meta {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
  }

  .viewers {
    margin-left: auto;
    padding: 0 0 0 15px;
    font-size: 13px;
    background: linear-gradient(to bottom, rgb(154 75 37), rgb(152 130 66));
    border-radius: 30px;
    padding: 6px 10px;
    color: white;
    text-shadow: 0 1px 2px rgba(0,0,0, 0.5);
  }
}

.loading {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-direction: column;
  padding: calc(var(--unit) * 3) 0 0;
  overflow: hidden;
}

.commentsContainer {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  overflow: hidden;
}

.comments {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  overflow: auto;
}

.comments + .commentForm {
  padding: 20px 0 0 0;
}

.grow {
  flex-grow: 1;

  .comment {
    border: 0 !important;
    padding: 25px 25px 0;

    &:last-child {
      padding-bottom: 25px;
    }
  }

  .container {
    flex-grow: 1;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    padding-top: 0;
    margin-top: calc(var(--unit) * 3);

    .form {
      margin-top: auto;
      padding: 25px;
    }
  }
}
