
.component {
  transition: background 250ms ease, opacity 250ms ease;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-self: center;
  align-items: center;
  padding: 6px 8px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  // transform: translate3d(0,0,0) scale(0.1);
  // backdrop-filter: blur(0px);
  // isolation: isolate;
  // backface-visibility: hidden;
  // will-change: transform, opacity, background, backdrop-filter;
  background: rgba(0,0,0,0.25);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  color: white;
  white-space: pre-wrap;
  opacity: 1;
  vertical-align: middle;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    opacity: 1;
  }

  &.isActive {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.35);
    font-weight: 700;
    opacity: 1;
  }
}

.icon {
  display: flex;
  border: 1px solid white;
  border-radius: 30px;
  padding: 3px;

  & svg {
    vertical-align: middle;
  }
}