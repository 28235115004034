
.component {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 25px;
  z-index: 2;
  overflow: hidden;

  // > .wave {
  //   width: 100%;
  //   flex-grow: 0;
  //   margin: auto;
  // }
}

.waveform {
  transition: opacity 250ms ease;
  width: 100%;
  height: 128px;
  pointer-events: none;
  opacity: 0;

  &.loaded {
    cursor: pointer;
    pointer-events: all;
    opacity: 1;
  }
}

.loader {
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  top: 50%;
  left: 50%;
  height: 128px;
}