.component {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  padding: calc(var(--unit) * 4);
  background-color: black;
  box-shadow: 0 8px 18px 0 rgba(0,0,0,0.34);
  overflow: hidden;

  &:before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    box-shadow: inset 1px 1px 0px 0 rgba(255,255,255,0.1);
    pointer-events: none;
  }

  &:first-child {
    border-top-left-radius: 19px;

    &:before {
      border-top-left-radius: 19px;
    }
  }

  &:last-child {
    border-top-right-radius: 19px;

    &:before {
      border-top-right-radius: 19px;
    }
  }
}

.cardInner {
  position: relative;
  display: flex;
  flex: 0 1 auto;
  align-items: inherit;
  justify-content: inherit;
  flex-direction: column;
  gap: calc(var(--unit) * 2);
  z-index: 1;
}

.cardBg {
  /* opacity: 0.35; */
  z-index: 0;
}

.cardTitle {
  font-size: 32px;
  font-weight: 700;
  font-family: Inter, var(--font-family);
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.85);
  color: white;
}

.cardBody {
  font-size: 19px;
  font-weight: 500;
  line-height: 27px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.5);

  & a, & em {
    color: white;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
  }

  & a {
    transition: border-color 250ms ease;
    border-bottom: 1px solid transparent;

    &:hover {
      border-color: rgba(255, 255, 255, 0.5);
    }
  }
}

.cardActions {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  align-items: flex-start;
  gap: calc(var(--unit) * 2);
}

.cardActionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: calc(var(--unit) * 2);
}

.cardAction {
  transition: background 250ms ease, color 250ms ease, box-shadow 250ms ease, transform 250ms ease;
  transform: translate3d(0, 0, 0);
  display: flex;
  flex: 0 0 auto;
  padding: calc(var(--unit) * 1.5);
  background: rgba(0,0,0,0.58);
  border: 0;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.50), inset 1px 1px 0 0 rgba(255,255,255,0.22);
  border-radius: 14px;
  font-size: 19px;
  font-weight: 600;
  color: white;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    transform: scale(1.01) translate3d(0, -2px, 0);
    transform-origin: 50%;
    background: white;
    box-shadow: 0 4px 32px 0 rgba(0,0,0,0.75), inset 1px 1px 0 0 rgba(255,255,255,0.22);
    color: black;
  }
}

.cardActionSuffix {
  font-size: 14px;
}

@media screen and (max-width: 800px) {
  .cardTitle {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  .cardBody {
    font-size: 14px;
  }

  .cardActions {
    flex-direction: column;

    .cardActionWrapper {
      flex-direction: row;
    }
  }
}
