.root {
  --container-border-radius: 19px;
  transform: translate3d(0,0,0);
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background: #08090A;
  box-shadow: 0 0 39px 9px rgba(0,0,0,0.50);
  border-radius: 19px;
  overflow: hidden;
}