.component {
  transition: transform 250ms ease, opacity 250ms ease;
  transform: translate3d(0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  background-color: black;
  pointer-events: none;
  opacity: 0;
  z-index: 1;
}

.picture {
  display: contents;
}

/* .loaded {
  opacity: 0.25;
} */

.color {
  transition: opacity 250ms ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  z-index: 0;
}
