.component {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
}

.tabs {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: calc(var(--unit) * 8) 0 0 0;
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1));
  width: 100%;
  pointer-events: none;
  z-index: 3;

  > div {
    pointer-events: all;
  }
}

.card {
  padding-bottom: calc(var(--unit) * 10);
}

.shareButton {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.50), inset 1px 1px 0 0 rgba(255,255,255,0.22), inset 0 0 0 2px currentColor;
  color: #fff699;
}

.top {
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-direction: row;
  border-radius: 19px 19px 0 0;
  overflow: hidden;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}