
.references {
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 -1px 0 0 rgba(255, 255, 255, 0.1);
  z-index: 2;

  .empty {
    padding: 20px;
    text-align: center;
    font-size: 16px;
  }

  .list {
    display: flex;
    flex-direction: column;
    max-height: 168px;
    overflow: auto;
  }

  .confirm {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: calc(var(--unit) * 2);
    box-shadow: 0 -1px 0 0 rgba(255, 255, 255, 0.1);
    z-index: 2;

    .reference {
      margin: calc(var(--unit) * -2);
      margin-right: var(--unit);
      border-bottom: 0;
      flex-grow: 1;
    }

    .btn {
      flex-grow: 0;
      // margin: 20px 20px 20px 0;
    }
  }

  .add {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 20px;
    box-shadow: 0 -1px 0 0 rgba(255, 255, 255, 0.1);
    z-index: 2;

    .url {
      transition: color 250ms ease;
      flex-grow: 1;
      background: transparent;
      border: 0;
      outline: 0;
      font-size: 17px;
      text-overflow: ellipsis;
      color: white;

      &.disabled {
        color: rgba(255, 255, 255, 0.5);
      }

      &::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.50);
      }
    }

    .btn {
      margin: 0 0 0 15px;
      padding: 9px;
      border-radius: 6px;
      font-size: 13px;
    }
  }
}
