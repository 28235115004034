.component {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  z-index: 0;

  > .header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    pointer-events: none;
    z-index: 2;

    .left, .center, .actions {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-shrink: 0;
      width: 33.3%;
    }

    .left {
      flex-shrink: 0;
      padding: 50px 15px 0 50px;
    }

    .center {
      padding: 35px 0 0 0;
      justify-content: center;
    }

    .actions {
      padding: 50px;
      justify-content: flex-end;

      & button {
        pointer-events: all;
      }
    }
  }
}

.currentDate {
  transform: translate3d(-50%, 0, 0);
  position: absolute;
  bottom: 25px;
  left: 50%;
  padding: 10px;
  font-size: 19px;
  font-weight: 700;
  border: 1px solid rgba(255, 255, 255, 0.5);
  user-select: none;
  font-variant-numeric: tabular-nums;
  cursor: ew-resize;

  .border {
    padding: 10px;
  }
}

.line {
  transform: translate3d(0, -50%, 0);
  position: absolute;
  bottom: 160px;
  right: 0;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.5);
  z-index: 1;
  pointer-events: none;
}

.indicator {
  transform: translate3d(-50%, 0, 0);
  position: absolute;
  bottom: 90px;
  left: 50%;
  width: 1px;
  height: 71px;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
  pointer-events: none;
}

.events {
  transform: translate3d(-50%, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2;

  &.isViewingEvent {
    .event .dot, .timelineDate {
      opacity: 0.5;
    }
  }
}

.scrollOuter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 0;

  .scroll {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
  }
}

.hero {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: 0;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    box-shadow: inset 0 0 0 1px rgba(255,255,255,0.1);
    border-radius: var(--container-border-radius);
    z-index: 1;
  }
}

.browser {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  z-index: 1000;

  .inner {
    display: flex;
    width: 600px;
    max-height: 80vh;
    cursor: default;
    overflow: hidden;
  }
}

.marker {
  position: relative;
  transform: translate3d(var(--x), calc(-50% - 0.5px), 0);
  position: absolute;
  bottom: 136px;
  right: -1px;
  height: 50px;
  margin: 0 0 0 -15px;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  opacity: 0.5;

  .label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0 0 8px;
    font-weight: 500;
  }
}