
.component {
  transition: background-size 250ms ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  background-size: 100% 100%;
  padding: 15px;
  z-index: 2;
}

.count {
  padding: 7px 9px;
  font-size: 12px;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  color: white;
  z-index: 1;
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.25));
}