.collection {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;
  text-align: left;
  overflow: hidden;
}

.error {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
}

.bar {
  box-shadow: 0 6px 12px 0 rgba(0,0,0,0.50);
  z-index: 1;
}

.container {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: row;
  padding: calc(var(--unit) * 3);
  background-color: #0e0e10;
  overflow: hidden;
}

.collectionChildren {}

.collectionDescription {
  background: rgba(255, 255, 255, 0.05);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px;

  .inner {
    border-left: 4px solid rgba(255, 255, 255, 0.15);
    padding: 5px 0 5px 10px;
    font-size: 17px;
    font-weight: 500;
    font-style: italic;
    color: rgba(255, 255, 255, 0.75);
  }
}

.preview {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  overflow: hidden;

  > * {
    flex: 1 1 auto;
    border-radius: 9px;
    overflow: auto;
  }

  /* @media screen and (max-width: 800px) { */
    // flex-shrink: 0;
  /* } */
}

.file {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: left;
  overflow: hidden;
}

.about {
  display: flex;
  flex: 0 0 auto;
  width: 400px;
  flex-direction: column;
  align-self: stretch;
  gap: calc(var(--unit) * 3);
  padding: 0 0 0 calc(var(--unit) * 3);
  overflow: auto;

  .meta {
    flex: 0 0 auto;
    padding: calc(var(--unit) * 2);
    border: 1px solid rgba(255,255,255,0.1);
    background-color: rgba(255,255,255,0.05);
    // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 9px;
    overflow: hidden;

    > .description {
      flex-shrink: 0;
      border-left: 4px solid rgba(255, 255, 255, 0.15);
      padding: 5px 0 5px 10px;
      font-size: 17px;
      font-weight: 500;
      font-style: italic;
      word-wrap: break-word;
      color: rgba(255, 255, 255, 0.75);
    }

    > .description + .date {
      padding: 15px 0 0 0;
    }

    .date {
      flex-shrink: 0;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .comments {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    padding: calc(var(--unit) * 2);
    background-color: rgba(255,255,255,0.05);
    box-shadow: 0 6px 12px 0 rgba(0,0,0,0.15);
    border-radius: 9px;
    overflow: hidden;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .container {
    flex-direction: column;
    overflow: auto;
  }

  .hideMedium {
    display: none;
  }

  .about {
    padding: calc(var(--unit) * 3) 0 0;
    width: auto;
  }
}

@media screen and (max-width: 600px) {
  .container {
    flex-direction: column;
    overflow: auto;
  }

  .hideMedium {
    display: none;
  }

  .hideSmall {
    display: none;
  }

  .about {
    padding: calc(var(--unit) * 3) 0 0;
    width: auto;
  }
}
