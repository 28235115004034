.component {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  overflow: auto;
  --font-serif: 'Times New Roman', Times, serif;

  * {
    user-select: none;
  }

  & img, & video, & canvas {
    pointer-events: none;
  }

  // &:before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: url(public/images/god-is-partying/space.png);
  //   // background-size: 100% 100%;
  //   z-index: 1;
  //   user-select: none;
  // }
}

.scroll {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100vw;
  overflow: hidden;
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, #0D0E0E 0%, #131215 500px, #030203 70%, rgb(6, 6, 6) calc(100% - 400px), #131215 100%);
  z-index: -1;
}

.creation {
  position: relative;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  // height: 400px;
  align-items: center;
  justify-content: center;
  // overflow: hidden;
  z-index: 0;

  .inner {
    position: relative;
    display: flex;
    max-width: 88vw;
    flex-direction: column;
    align-items: center;
    margin: 135px 0;
    z-index: 2;

    @media screen and (max-width: 1080px) {
      margin: 135px 0 70px;
    }

    @media screen and (max-width: 710px) {
      margin: 100px 0 70px;
    }

    .logo {
      max-width: 100%;
      filter: drop-shadow(0 2px 13px black);
    }

    .gip {
      padding: 5px 0 0;
      font-size: 72px;
      font-family: var(--font-serif);
      text-transform: uppercase;
      color: white;
      text-shadow: -3px 0 0 blue, 3px 0 0 red;
      filter: drop-shadow(0 2px 13px black);

      @media screen and (max-width: 710px) {
        font-size: 10.4vw;
      }

      // @media screen and (max-width: 710px) {
      //   // font-size: 47px;
      //   letter-spacing: 1.8px;
      // }
    }

    .countdown {
      display: flex;
      padding: 10px 0 0;
      flex-direction: row;
      font-family: "Arial";
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 600;
      filter: drop-shadow(0 2px 2px black);
      white-space: pre;
      font-variant-numeric: tabular-nums;

      & em {
        font-style: normal;
        color: white;
        text-shadow: -1px -1px blue, 1px 1px 1px red;
      }

      .available {}

      .part {
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.25), 0 1px 0 0 rgba(255 , 255, 255, 0.1);
        padding: 12px;
        margin: 0 6px 0 0;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 9px;

        @media screen and (max-width: 710px) {
          margin: 0 5px 0 0;
          padding: 12px 8px;
          font-size: 12px;
        }

        // &:first-child {
        //   padding-left: 0;
        // }
      }

      .unit {
        opacity: 0.5;
      }
    }

    .cube {
      // transform: translate3d(50%, -50%, 0) scale(0.65);
      // position: absolute;
      // bottom: -75px;
      // right: 50%;
      // top: 50%;
      // transform: scale(0.75);
      padding: 25px 0 0 0;
      filter: drop-shadow(0 4px 16px black);
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background-image: linear-gradient(to bottom, #0d0e0e00, #131215);
    z-index: 1;
  }

  .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    object-fit: cover;
    z-index: 0;
  }
}

.babalon, .heaven, .sins {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  z-indeX: 2;
}

.embed {
  width: min(80vw, 700px);
  height: 370px;
  border-radius: 19px;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 0 36px 6px black;
  background: black;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .ratio {
    width: 100%;
    visibility: hidden;
  }

  @media screen and (max-width: 1080px) {
    height: 240px;
  }
}

@keyframes aliceSpin {
  0% {
    transform: rotate(40deg);
  }

  100% {
    transform: rotate(400deg);
  }
}

.babalon {
  z-index: 3;

  .heh {
    transform: perspective(23px) skew(0deg, -10deg) rotateX(14deg) rotate(50deg);
    position: absolute;
    top: 100px;
    left: -160px;
    width: 100px;
    filter: grayscale();
    opacity: 0.015;
  }

  .frame {
    position: absolute;
    width: 900px;
    left: -125px;
  }

  .embed {
    position: relative;
    // transform: perspective(553px) skew(0deg, -10deg) rotateX(0deg); // skew(-10deg, 0) rotate3d(1, 0, 1, -17deg);
    top: -50px;
    transform: perspective(573px) skew(0deg, -10deg) rotateX(20deg);
    box-shadow: -3px -3px 6px 0px rgba(0, 0, 255, 0.52), 3px 3px 6px 0px rgb(255, 28, 28), 6px 6px 0 0 rgba(255, 28, 28, 0.15);

    @media screen and (max-width: 1080px) {
      transform: none;
      top: 0;
    }
  }

  .discord {
    position: relative;
    transition: opacity 1s ease;
    transform: translate3d(120px, -50px, 0) rotateY(180deg) rotate(-30deg);
    margin: 0 0 0 50px;

    .invite {
      position: absolute;
      right: 20px;
      top: 160px;
      width: 45px;
      height: 40px;
      border-radius: 50%;
    }

    .color {
      transition: opacity 1s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    & video, & img {
      opacity: 0.25;
    }

    @media screen and (max-width: 1080px) {
      // display: none;
      transform: translate3d(0, 0, 0) rotateY(180deg) rotate(-30deg);
      position: absolute;
      top: -180px;
      right: -35px;

      & img {
        width: 120px;
      }

      .invite {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &.hovering {
      .color {
        opacity: 0.1;
      }
    }
  }

  .alice {
    animation: aliceSpin 20s linear;
    transition: filter 500ms ease, opacity 500ms ease;
    transform: rotate(40deg);
    position: absolute;
    bottom: -150px;
    right: 140px;
    width: 200px;
    filter: grayscale(1);
    opacity: 0.02;
    pointer-events: all;
    animation-play-state: paused;

    &:hover {
      animation-play-state: running;
      transform: rotate(390deg);
      // filter: grayscale(0);
      opacity: 0.025;
    }
  }
}

.heaven {
  padding: 150px 0;

  > .mask {
    transform: translate3d(0, 0, 150px)  perspective(22px) skew(0deg, -10deg) rotateX(14deg) rotate(50deg);
    position: absolute;
    width: 200px;
    top: 140px;
    right: 260px;
    opacity: 0.025;
    filter: invert(1);
    z-index: 0;
  }

  .frame {
    position: absolute;
    width: 970px;
    transform: skewY(10deg);
    right: -135px;
    z-index: 2;
    pointer-events: none;
    // opacity: 0.15;
    // filter: invert(1) hue-rotate(10deg) brightness(0.15);
  }

  .wolf {
    position: absolute;
    top: 300px;
    left: 290px;
    width: 200px;
    z-index: 0;
    // filter: grayscale(1);
    transform: translate3d(0, 0, 250px) perspective(573px) skew(0deg, 0deg) rotateX(20deg) rotate(-10deg);
    opacity: 0.02;
  }

  .cube {
    position: absolute;
    top: 550px;
    left: 200px;
    filter: drop-shadow(-12px -12px 13px black);
    z-index: 3;

    @media screen and (max-width: 1080px) {
      top: 240px;
      left: unset;
      right: 40px;
      opacity: 0.85;
    }
  }

  .embed {
    transform: skewY(10deg);
    height: 493px;
    z-index: 1;
  }


  @media screen and (max-width: 1080px) {
    padding: 50px 0;

    > .mask {
      display: none;
    }

    .cube {
      top: 240px;
      left: unset;
      right: 40px;
      opacity: 0.85;
    }

    .embed {
      transform: none;
      height: 240px;
    }
  }
}

.ews {
  transform: translate3d(-100px, 60px, 0) rotate(-12deg);
  position: relative;
  margin: 0 100px 0 0;
  overflow: hidden;

  @media screen and (max-width: 1080px) {
    position: absolute;
    opacity: 0.5;
  }

  .mirror {
    position: relative;
    pointer-events: none;
    z-index: 1;
  }

  .mask {
    transform: translate3d(-50%, 0, 0);
    position: absolute;
    top: 120px;
    left: 50%;
    height: 360px;
    width: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    overflow: hidden;
  }

  .sinner {
    display: block;
    pointer-events: all;
    height: 100%;

    .sinnerVideo {
      height: 100%;
    }
  }
}

.sins {
  .embed {
    transform: translate3d(0, 0, 0);
    width: min(80vw, 1260px);
    height: auto;
  }

  .rco {
    transform: rotate(-15deg);
    position: absolute;
    width: 400px;
    top: -50px;
    left: -260px;
    opacity: 0.05;
  }

  .throne {
    transform: rotate(15deg);
    position: absolute;
    width: 400px;
    top: 60px;
    right: -260px;
    opacity: 0.05;
  }

  .cube {
    position: absolute;
    top: -130px;
    left: 140px;
    filter: drop-shadow(-12px -12px 13px black);
  }


  @media screen and (max-width: 1080px) {
    .embed {
      height: 240px;
      width: min(80vw, 700px);
    }

    .throne {
      display: none;
    }
  }
}

.footer {
  position: relative;
  display: flex;
  width: 100vw;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  margin: auto 0 0 0;
  padding: 50px 0 0 0;
  z-index: 0;

  .otto {
    position: relative;
    margin: 25px auto;
    width: 125px;
    height: 141px;
    opacity: 1; // 0.2;
    z-index: 1;
    mix-blend-mode: screen;

    &.canClick {
      cursor: pointer;
    }

    .still, .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }

  .inner {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 35px 20px;
    align-items: stretch;
    justify-content: center;
    // border-top: 1px solid rgba(255, 255, 255, 0.15);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    z-index: 1;

    & label {
      position: relative;
      font-size: 15px;
      padding: 35px 0;
      text-align: center;
      overflow: hidden;
      white-space: pre-wrap;
      color: rgba(255, 255, 255, 0.5);
      z-index: 1;

      &:before, &:after {
        position: absolute;
        top: 51%;
        overflow: hidden;
        width: 50%;
        height: 1px;
        content: '\a0';
        background-color: rgba(255, 255, 255, 0.15);
      }

      &:before {
        margin-left: -50%;
        text-align: right;
      }

      & em {
        font-style: normal;
        font-weight: 600;
      }
    }
  }

  .links {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-self: center;
    justify-content: center;
    z-index: 2;

    .link {
      transition: background-color 250ms ease, color 250ms ease, box-shadow 250ms ease, border-color 250ms ease;
      display: block;
      margin: 0 0 15px 15px;
      border: 2px solid currentColor;
      border-radius: 9px;
      padding: 12px;
      color: rgba(255, 255, 255, 0.25);
      font-weight: 600;
      text-decoration: none;
      // box-shadow: 0 6px 12px 0 rgba(0, 0, 0,0.5);
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
      text-transform: uppercase;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background-color: black;
        border-color: black;
        color: white;
        box-shadow: inset 0 0 0 2px #75140C, 0 0 0 2px black, 0 6px 12px 0 rgba(0, 0, 0,0.5);
        // text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.25);
      }

      &:first-child {
        margin-left: 0;
      }
    }

    .bandcamp {
      --color: #4A9FBF;
      --color-b: #206e8a;
    }

    .apple {
      --color: #E96575;
      --color-b: #E63F45;
    }

    .itunes {
      --color: #D85BBA;
      --color-b: #BC56EC;
    }

    .spotify {
      --color: #09b16b;
      --color-b: #2c7d5c;
    }

    .amazon {
      --color: #F29D38;
      --color-b: #EE9B38;
    }

    .napalm {

    }

    .deezer {

    }

    .youtube {

    }

    .awk {

    }
  }

  .notice {
    position: relative;
    padding: 15px;
    background: black;
    font-size: 10px;
    text-transform: uppercase;
    color: white;
    z-index: 1;

    & a {
      font-weight: 600;
      color: white;
      text-decoration: none;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom-color: white;
      }
    }
  }

  @media screen and (max-width: 710px) {
    padding: 0;

    .inner {
      padding: 0 35px 0;

      & label {
        padding: 25px 0;
      }
    }

    .otto {
      margin: 25px auto 0;
    }

    .links {
      flex-direction: column;
      align-items: stretch;
      align-self: stretch;

      .link {
        margin: 0 0 15px;
      }
    }

    .footerCover {
      display: none;
    }
  }
}

.footerCover {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100vw;
  height: 400px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 0;

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgb(6, 6, 6), #131215d0);
    z-index: 1;
  }

  .cover {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    // height: 400px;
    object-fit: cover;
    max-height: 100%;
    z-index: 0;
  }
}

.cube {
  width: 75px;
  height: 75px;
}

.credit {
  transition: background 250ms ease, color 250ms ease, opacity 250ms ease;
  position: absolute;
  top: 25px;
  left: 25px;
  display: block;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 9px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  z-index: 1;

  &:hover {
    opacity: 1;
    background: white;
    color: black;
  }
}

.iwtsygwC {
  position: relative;
  margin: auto;
  width: 900px;
  height: 0;
}

.iwtsygw {
  transition: opacity 250ms ease;
  transform: rotate(20deg);
  position: absolute;
  top: 98px;
  left: -140px;
  width: 306px;
  height: 166px;
  z-index: 0;
  mix-blend-mode: screen;
  opacity: 0.05;
}