
.component {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: center;
  padding: 20px;
  font-size: 17px;
  overflow: hidden;
  z-index: 2;
}

.wrapped {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.imagePreview {
  position: relative;
  display: inline-grid;
  flex-shrink: 0;
  /* margin: 20px 0 20px 20px; */
  min-height: 110px;
  border-radius: 0px;
  overflow: hidden;
  z-index: 0;

  .actions {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: row;
  }

  .btn {
    transition: opacity 250ms ease;
    background-color: black;
    z-index: 2;
    opacity: 0;

    .icon {
      margin: 0;
    }
  }

  .image {
    background-color: black;
  }

  .centerButton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1f1f1f;
    z-index: 2;
  }

  &:hover .btn {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .sizer {
    max-width: 200px;
    height: 100%;
    opacity: 0;
  }
}
 
.eventInfo {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: stretch;
  padding: calc(var(--unit) * 2);

  .input.description {
    min-height: 120px;
    resize: vertical;
  }
}

.input {
  flex-grow: 1;
  padding: 12px !important;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  font-size: 16px;
  outline: 0;
  color: rgba(255, 255, 255, 0.5);

  &:focus {
    color: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, 0.25);
  }

  &.description {
    margin: 10px 0 0 0;
    resize: none;
  }

  &:invalid, &.invalid {
    border-color: #75140C;
    background-color: rgba(117, 20, 12, 0.2);
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: calc(var(--unit) * 2);
}

.save {
  margin-left: auto;
  background-image: linear-gradient(180deg, #48952C 0%, #18570E 100%);
}

.top {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  align-items: center;

  .btn {
    margin-left: auto;
    padding: 9px;
    border-radius: 6px;
    font-size: 13px;

    &.edit {
      .icon {
        margin: 0;
        vertical-align: middle;
      }
    }
  }
}

.title {
  padding: 0 15px 5px 0;
  font-size: 19px;
  font-weight: 600;
  line-height: 27px;
  color: white;
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -10px;

  .tag {
    margin: 0 0 10px 10px;
    padding: 6px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    color: white;
  }
}

.description {
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.5);
}

.rowLabel {
  padding: 15px 0 0 0;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
}

.row {
  display: flex;
  flex-direction: row;
  padding: 10px 0 0 0;

  .input + .input {
    margin: 0 0 0 10px;
  }
}

.footer {
  padding: 10px 0 0 0;
  font-size: 14px;
  font-style: italic;
  color: rgba(255, 255, 255, 0.35);
}