
.component {
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  flex-direction: row;
  align-self: stretch;
  justify-content: center;
  background-color: rgba(0, 0, 0);
  padding: 25px;
  overflow: auto;
}

.contents {
  white-space: pre-wrap;
  flex-grow: 0;
  flex-shrink: 1;
  font-size: 17px;
  line-height: 24px;
  color: white;
}