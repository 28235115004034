.component {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  cursor: url(~public/images/cursors/pen.png), auto;
  cursor: -webkit-image-set(
    url(~public/images/cursors/pen.png) 1x,
    url(~public/images/cursors/pen@2x.png) 2x
  ), auto;
  z-index: 3;

  &.close {
    cursor: url(~public/images/cursors/pen-close.png), auto;
    cursor: -webkit-image-set(
      url(~public/images/cursors/pen-close.png) 1x,
      url(~public/images/cursors/pen-close@2x.png) 2x
    ), auto;
  }
}