.banner.godIsPartying {
  transition: background-size 500ms ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(~public/images/banners/godispartying.jpg);
  background-size: 100% auto;
  background-position: 50% 50%;
  padding: 15px 100px 15px 100px;
  color: white;
  font-size: 17px;
  font-weight: 800;
  text-decoration: none;
  box-shadow: 0 2px 9px 0 black;
  z-index: 1;

  &:before {
    transition: opacity 250ms ease;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, #000000, #e01100 50%, #000dff 50%, #000000);
    opacity: 0.5;
    z-index: 1;
  }

  .copy {
    position: relative;
    display: flex;
    flex-direction: row;
    background: white;
    color: black;
    border: 3px solid black;
    box-shadow: 3px 4px 6px 0 rgba(0, 0, 0, 0.5);
    padding: 2px;
    background: linear-gradient(to right,  #e01100 50%, #000dff 50%);
    white-space: nowrap;
    z-index: 2;

    .inner {
      background: black;
      color: white;
      padding: 5px;
    }

    .date {
      background: white;
      color: black;
    }
  }

  &:hover:not(.isHoveringDismiss) {
    background-size: 105% auto;

    &:before {
      opacity: 0;
    }

    .copy {
      background: black;
      flex-direction: row-reverse;

      .inner, .date {
        background: black;
        color: white;
      }

      .inner {
        color: #0080ff;
      }

      .date {
        color: #f9392d;
      }
    }
  }
}

.dismissOverlay {
  transition: opacity 250ms ease;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0;
  z-indeX: 3;
}

.isHoveringDismiss .dismissOverlay {
  opacity: 0.75;
}

.dismiss {
  transform: translate3d(0, -50%, 0);
  transition: background 250ms ease;
  position: absolute;
  right: 16px;
  top: 50%;
  padding: 9px;
  border-radius: 30px;
  z-index: 4;

  @media screen and (max-width: 600px) {
    & svg {
      width: 26px;
      height: 26px;
    }
  }

  & svg {
    vertical-align: middle;
  }

  &:hover {
    background: rgba(0, 0, 0, 1);
  }
}

@media screen and (max-width: 600px) {
  .copy {
    font-size: 13px;
  }

  .dismiss {
    right: 6px;
  }
}
