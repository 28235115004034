
.fileInput {
  position: absolute;
  top: -9000px;
  left: -9000px;
}

.box {
  position: relative;
  padding: 25px;
  background: black;
  box-shadow: 0 29px 48px 0 black;
  z-index: 2;
  --outline-color: #75140C;

  .inner {
    position: relative;
    padding: 0 0 15px 0;
    z-index: 2;

    &:before {
      transform: translate3d(0, 0, 0);
      content: '';
      position: absolute;
      background: rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      top: 12px;
      left: 0;
      width: 100%;
      height: calc(100% - 12px);
      box-shadow: inset 0 0px 0 1px var(--outline-color);
      z-index: 1;
    }
  }

  .title {
    transform: translate3d(0, 0, 0);
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 15px;
    z-index: 3;

    .line {
      opacity: 0;
      flex-grow: 1;
      height: 1px;
      background: linear-gradient(to right, var(--outline-color), transparent);
    }

    .text {
      padding: 0;
      letter-spacing: 1px;
      font-size: 21px;
      font-weight: 700;
      text-transform: uppercase;
      color: white;
    }

    .text + .line {
      background: linear-gradient(to left, var(--outline-color), transparent);
    }
  }

  .body {
    position: relative;
    padding: 5px 15px 15px;
    font-size: 19px;
    line-height: 29px;
    color: #ccc;
    z-index: 3;

    & em {
      font-style: normal;
      font-weight: 600;
      color: white;
    }
  
    & a {
      color: white;
      font-weight: 600;
      text-decoration: none;
      border-bottom: 1px solid transparent;
  
      &:hover {
        border-bottom-color: white;
      }
    }
  
    & i {
      color: #616161;
      letter-spacing: 0.3ch;
    }
  }
}

/*
.inner {
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  max-width: 800px;
  max-height: 90%;
  flex-direction: column;
  align-items: flex-start;
  background: black;
  box-shadow: 0 29px 48px 0 black;
  padding: 25px;
  text-align: center;
  z-index: 2;
  overflow: hidden;

  .border {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 1;
    padding: 25px;
    text-align: center;
    border: 2px solid #75140C;
    overflow: auto;
  }
}
*/

.upload {
  position: relative;
  transition: background-size 250ms ease;
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-direction: column;
  padding: calc(var(--unit) * 6) calc(var(--unit) * 6) 0;
  overflow: hidden;
  z-index: 2;

  &.isDropping {
    border-style: dashed;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.15);
  }

  &.pending:hover {
    background-size: 100% 200%;
  }

  .uploadInner {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background: rgba(255,255,255,0.05);
    box-shadow: 0 4px 32px 0 rgb(0 0 0 / 50%), inset 1px 1px 0 0 rgba(255,255,255,0.1);
    border-radius: 9px;  
    overflow: auto;
  }

  .row {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    flex-direction: row;
    align-items: flex-start;
    padding: calc(var(--unit) * 3) calc(var(--unit) * 4);
    border-bottom: 1px solid rgba(255,255,255,0.05);

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0 15px 0;
      font-size: 19px;
      font-weight: 600;
      flex-shrink: 0;
      color: white;

      .action {
        transition: border-color 250ms ease;
        border: 1px solid rgba(255, 255, 255, 0.15);
        padding: 12px;
        border-radius: 9px;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        color: white;
        background: none;
        outline: 0;
        margin-left: auto;
        cursor: pointer;

        background: rgba(0,0,0,0.58);
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.50), inset 1px 1px 0 0 rgba(255,255,255,0.1);
        border-radius: 14px;
        border: 0;

        &:hover {
          border-color: rgba(255, 255, 255, 0.25);
        }
      }
    }

    .label {
      font-weight: 600;
      flex-shrink: 0;
      padding: calc(var(--unit) * 1.5);
      box-shadow: inset 0 0 0 1px rgba(255,255,255,0.25);
      border-radius: 9px;
      color: white;
    }

    .input {
      flex-shrink: 1;
      flex-grow: 1;
      background: none;
      border: 0;
      outline: 0;
      padding: calc(var(--unit) * 1.5);
      font-weight: 400;
      flex-shrink: 0;
      color: white;
    }
  }

  .filesRow {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 0;
    overflow: hidden;

    .row {
      align-items: stretch;
      flex-grow: 1;
      flex-shrink: 1;
    }

    &.isDropping > div {
      pointer-events: none;
    }
  }

  .files {
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
  }

  .error {
    margin: 20px 0;
    background: #e4868612;
    padding: 20px;
    border-radius: 6px;
    color: white;
    font-weight: 500;
  }

  .submit {
    padding: calc(var(--unit) * 4) calc(var(--unit) * 3);
    align-items: center;
    border-bottom: 0;

    .warn {
      flex-grow: 1;
      text-align: left;
      font-size: 17px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.35);
    }

    .progress {
      position: relative;
      flex-grow: 1;
      align-self: stretch;
      border-radius: 9px;
      margin: 0 12px 0 0;
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
      overflow: hidden;

      .text {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 600;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
        color: white;
        z-index: 1;
      }

      .progressInner {
        transition: width 250ms linear;
        background: #FFFFFF;
        width: 0;
        height: 100%;
        opacity: 0.1;
      }
    }
  }
}

.items {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: calc(var(--unit) * 3);
  // backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.05);
  box-shadow:  0 8px 18px 0 rgba(0,0,0,0.34);
  border-radius: 15px;
  overflow: auto;

  .dropping {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.75);
    font-size: 17px;
    font-weight: 700;
    color: white;
    z-index: 1;
  }
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 15px;
  width: 150px;
  overflow: hidden;
  user-select: none;

  .remove {
    transition: opacity 250ms ease, background-size 250ms ease;
    transform: translate3d(-50%, -50%, 0);
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 6px 9px;
    background: linear-gradient(to bottom, red, rgb(141, 26, 26));
    background-size: 100% 100%;
    border-radius: 30px;
    font-size: 10px;
    font-weight: 800;
    text-transform: uppercase;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.35);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    z-index: 1;

    &:hover {
      background-size: 100% 200%;
    }
  }

  &.removable:hover {
    .remove {
      opacity: 1;
      pointer-events: all;
    }

    .inner {
      filter: blur(2px);
      opacity: 0.5;
    }
  }

  .inner {
    transition: filter 250ms ease, opacity 250ms ease;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
  }

  .icon {
    height: 74px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .name {
    padding: 8px 0 0 0;
    font-size: 13px;
    font-weight: 700;
    line-height: 17px;
    text-align: center;
    word-break: break-all;
    color: white;
  }

  .size {
    padding: 5px 0 0 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
  }

  &.audio .icon {
    width: 75px;
    background-image: url(~public/images/files/audio.png);
    background-size: 75px 74px;
  }

  &.file .icon {
    width: 52px;
    background-image: url(~public/images/files/file.png);
    background-size: 52px 69px;
  }

  &.rar .icon {
    width: 56px;
    background-image: url(~public/images/files/rar.png);
    background-size: 56px 72px;
  }

  &.zip .icon {
    width: 56px;
    background-image: url(~public/images/files/zip.png);
    background-size: 56px 72px;
  }

  &.pdf .icon {
    width: 55px;
    background-image: url(~public/images/files/pdf.png);
    background-size: 55px 72px;
  }
}

// .art {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   min-height: 100%;

//   .bg {
//     object-position: 50% 30% !important;
//   }
// }

@keyframes fadeInOverlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.droppingOverlay {
  animation: fadeInOverlay 250ms ease forwards;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  z-index: 100;

  .inner {
    position: relative;
    width: 650px;
    padding: 50px;
    background: black;
    box-shadow: 0 0 0 1px #75140C, 0 0 0 25px rgba(0, 0, 0, 1);
    overflow: hidden;
    opacity: 1;

    .title {
      font-size: 42px;
      font-weight: 700;
      color: white;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 170px;
      background-image: url(~public/images/albums.png);
      background-position: 50% 0;
      background-size: 750px auto;
      opacity: 0.15;
    }
  }
}

.creator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: var(--header-height) calc(var(--unit ) * 4) 0;
  display: flex;
  background: black;
  overflow: auto;
  z-index: 99;

  .bg {
    height: 100%;
    object-position: 50% 30% !important;
  }

  // .shadow {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 25px;
  //   background: linear-gradient(to bottom, rgba(0, 0, 0, 0.35), transparent);
  //   z-index: 2;
  // }

  .upload {
    position: relative;
    flex-grow: 1;
    z-index: 2;
  }
}

// @media screen and (max-width: 600px) {
//   .top {
//     flex-grow: 1;

//     .welcome {
//       flex-grow: 0;
//     }
//   }

//   .page {
//     display: none;
//   }
// }

@media screen and (max-height: 900px) {
  // .component {
  //   overflow: auto;
  // }

  .page {
    flex-shrink: 0;
  }
}

