.component {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 75px;
  padding: 0 calc(var(--unit) * 3);
  align-items: center;
  // background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.025));
  // border-top: 1px solid rgba(255, 255, 255, 0.1);
  // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: left;
  color: white;
  overflow: hidden;
}

.title {
  flex-grow: 1;
  flex-shrink: 1;
  width: 0;
  padding: 0 15px;
  font-size: 19px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.actions {
  display: flex;
  margin-left: auto;
  flex-direction: row;
}