.component {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  background: linear-gradient(to bottom, rgb(38 41 49), rgb(10, 9, 10));
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    box-shadow: inset 0 0 0 1px rgba(255,255,255,0.1);
    border-radius: var(--container-border-radius);
    pointer-events: none;
    z-index: 3;
  }
}

.inner {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  overflow: hidden;
}

.error {
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  padding: 25px;
  font-size: 26px;
  font-weight: 600;
  color: white;
}

.left {
  width: 420px;
  height: 100%;
  pointer-events: none;
  z-index: 2;

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 420px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    background: linear-gradient(to bottom, rgb(38 41 49), rgb(10, 9, 10));
    box-shadow: 3px 0 19px 0 rgba(0, 0, 0, 0.5);
    overflow: auto;
    pointer-events: all;
  }

  @media screen and (min-width: 1080px) {
    .inner {
      transition: transform 250ms ease;
    }

    &.collapsed {
      position: absolute;
      top: 0;
      left: 0;

      .leftTrigger {
        position: absolute;
        top: 0;
        left: 0;
        width: 100px;
        height: calc(100% - 100px);
        pointer-events: all;
      }

      .inner {
        transform: translate3d(-100%, 0, 0);
      }

      &:hover {
        .inner {
          transition-delay: 250ms;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

.cover {
  position: relative;
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  user-select: none;

  &.expand {
    flex-grow: 1;
  }

  .soon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
    justify-content: center;
    padding: 25px;
    font-size: 26px;
    font-weight: 600;
    background: rgba(0, 0, 0, 0.65);
    text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
    color: white;
  }

  .preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    background: black;
    background-size: cover;
    background-position: 50% 50%;
    box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px, rgb(0 0 0 / 73%) 0px 16px 10px -10px;
    overflow: hidden;
    z-index: 0;
  }

  .player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    background: black;
    z-index: 0;

    &.activePlayer {
      display: flex;
      z-index: 1;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: left;
  padding: 10px 25px 25px 25px;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;

  > .name {
    flex-shrink: 0;
    padding: 10px 0 25px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
    word-break: break-word;
    color: white;
  }

  > .status {
    display: table;
    flex-shrink: 0;
    font-size: 17px;
    line-height: 28px;
    table-layout: fixed;

    .kv {
      display: table-row;

      & label {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        display: table-cell;
        padding: 15px 15px 15px 0;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.75);
      }

      .value {
        transition: color 250ms ease;
        display: table-cell;
        text-align: right;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        width: 100%;
        color: rgba(255, 255, 255, 0.35);

        .button {
          padding: 9px;
          font-size: 15px;
          opacity: 0.5;

          &:hover {
            opacity: 1;
          }
        }

        .secret {
          transition: filter 250ms ease;
          filter: blur(6px);
          font-family: monospace;

          &:hover {
            filter: blur(0);
          }
        }

        &.padded {
          padding: 15px 0;
        }
      }

      &:hover {
        .value {
          color: white;
        }
      }

      &:last-child {
        & label, .value {
          border-bottom: none;
        }
      }
    }

    .kvExplainBody {
      display: block;
      text-align: right;
      padding: 5px 0 15px;
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      color: white;
      opacity: 0.5;
    }

    .value {
      font-weight: 600;
      font-style: normal;
      color: white;
    }
  }

  > .actions {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    padding: 25px 0 0 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    .volume {
      justify-content: center;
    }

    .volume + .share {
      margin: 0 0 0 15px;
    }

    .share {
      --color: rgba(255, 255, 255, 0.5);
      display: flex;
      flex-grow: 1;
      flex-direction: row;
      background: none;
      border: 2px solid rgb(92 96 101);
      border-radius: 12px;
      overflow: hidden;

      .label {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        background: linear-gradient(to bottom, rgb(92, 96, 101), rgb(41 43 45));
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.85);
      }

      .link {
        transition: color 250ms ease;
        flex-grow: 1;
        min-width: 0;
        padding: 12px;
        background: none;
        border: 0;
        outline: 0;
        font-size: 21px;
        color: rgba(255, 255, 255, 0.5);

        &:focus {
          color: white;
        }
      }
    }
  }

  .contents {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}

.tracksHeader {
  display: flex;
  flex-shrink: 0;
  padding: 18px 25px;
  /*background: linear-gradient(to bottom, rgb(5 3 10), #23203a);*/
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1), 0 3px 19px 0 rgba(0, 0, 0, 0.5);
  font-weight: 800;
  font-size: 13px;
  letter-spacing: 0.5ch;
  justify-content: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.85);
  z-index: 1;
}

.tracks {
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: -3px 0 19px 0 rgba(0, 0, 0, 0.5);
  text-align: left;
  overflow: auto;

  @media screen and (max-height: 700px) {
    flex-shrink: 0;
    overflow: visible;
  }

  .file {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
    color: white;

    .status {
      padding: 15px 15px 15px 25px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.5);
      text-align: center;
      text-transform: uppercase;
      font-variant-numeric: tabular-nums;
      white-space: nowrap;
      min-width: 115px;
    }

    &.playing {
      .status {
        font-weight: 800;
        color: #00ff97;
      }
    }

    .name {
      padding: 15px 15px 15px 0;
      font-size: 15px;
      font-weight: 600;
      line-height: 24px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .link {
      transition: opacity 250ms ease;
      margin-left: auto;
      padding: 15px 25px 15px 15px;
      color: white;
      opacity: 0;

      & svg {
        width: 18px;
      }
    }

    &:hover {
      .link {
        opacity: 0.25;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    padding: 25px;
    font-size: 19px;
    font-weight: 600;
    color: white;
  }
}

.add {
  transition: background-size 250ms ease, opacity 250ms ease;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 15px;
  background-image: linear-gradient(180deg, #101113 0%, #000000 100%);
  background-size: 100% 100%;
  border-radius: 0 0 1px 1px;
  box-shadow: 0 3px 19px 0 rgba(0, 0, 0, 0.5);
  border: 0;
  font-size: 17px;
  font-weight: 600;
  color: white;
  outline: 0;
  cursor: pointer;

  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background-size: 100% 200%;
  }
}

.comments {
  position: relative;
  display: flex;
  width: 400px;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: -3px 0 19px 0 rgba(0, 0, 0, 0.5);
  padding: calc(var(--unit) * 3);
  text-align: left;
  overflow: hidden;
  z-index: 1;

  &.expand {
    width: auto;
    box-shadow: none;
    flex-grow: 1;
  }
}

.warning {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.85);
  color: white;
  z-index: 100;

  .title {
    padding: 0 0 25px 0;
    font-size: 36px;
    font-weight: 600;
  }
}

.button {
  transition: background 250ms ease, color 250ms ease;
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 12px;
  background: none;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  font-size: 21px;
  font-weight: 600;
  cursor: pointer;
  color: white;
  outline: 0;

  &:hover, &:active {
    background: white;
    color: black;
  }
}

@media screen and (max-width: 1080px) {
  .left {
    position: static !important;
    height: auto !important;

    .inner {
      position: static !important;
      width: auto !important;
      height: auto !important;
    }
  }

  // .preview {
  //   position: static;
  // }
  // .player {
  //   position: static;
  // }

  .component, .inner {
    flex-direction: column;
  }

  .info {
    padding: 0;

    .name {
      padding: 15px 25px;
    }

    .status {
      padding: 0 25px 0;
    }
  }

  .status.isActive, .info > .actions, .tracksHeader, .add {
    display: none;
  }

  .left, .comments {
    width: auto;
  }

  .tracks{
    .tracksHeader {
      display: none;
    }
  
    .file {
      display: none;

      .status {
        padding: 15px;
      }

      .name {
        padding: 0 15px 0 0;
      }

      .link {
        display: none;
      }

      &.playing {
        display: flex;
        height: 50px;
      }
    }
  }

  .left {
    flex-grow: 0;
    flex-shrink: 0;
    box-shadow: 0 3px 12px 2px rgba(0, 0, 0, 0.75);
  }

  .cover {
    flex-grow: 0;

    .player {
      position: static;
      flex: 0 0 auto;
      height: unset;
    }

    &.expand {
      flex-grow: 2;
    }
  }

  .comments {
    flex-grow: 0.5;

    &.expand {
      flex-grow: 1;
    }
  }
}

.browser {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  z-index: 1000;

  .inner {
    display: flex;
    width: 600px;
    max-height: 80vh;
    cursor: default;
    overflow: hidden;
  }
}