.component {
  padding: 15px;
  font-size: 24px;
  font-weight: 800;
  background-color: black;
  box-shadow: 0 13px 24px 0 rgba(0, 0, 0, 0.35);
  color: white;
  white-space: nowrap;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.border {
  padding: 15px;
  border: 1px solid #75140C;
}
