@keyframes pulse {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

.component {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.icon {
  animation: pulse 1s linear infinite reverse;
}