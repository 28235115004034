
.component {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);

  &.smallComment {
    align-items: flex-start;

    .avatar {
      width: 26px;
      height: 26px;
    }

    .body {
      border-radius: 12px;
      padding: 9px 12px;
      white-space: pre-wrap;
    }

    .right {
      height: auto;
      justify-content: center;
    }

    .right .user {
      padding-bottom: 0;
      padding: 0;
      font-size: 19px;
    }
  }

  &:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }

  &:last-chid {
    border-bottom: 0;
  }
}

.top {
  display: flex;
  flex-direction: row;
  padding: 0 0 15px 0;
}

.right {
  display: flex;
  height: 44px;
  flex-grow: 1;
  flex-direction: column;
  padding: 0 0 0 15px;

  .user {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 17px;
    padding: 0 0 7px 0;

    .name {
      color: white;
      font-weight: 600;
    }

    .tripCode {
      padding: 4px 6px;
      margin: 0 0 0 10px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      border-radius: 4px;
      font-size: 12px;
      font-family: monospace;
    }
  }

  .date {
    margin: auto 0;
    font-size: 13px;
  }
}

.body {
  flex-shrink: 0;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  font-size: 15px;
  line-height: 21px;
  word-break: break-word;
}
