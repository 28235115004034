.component {
  transition: background-color 250ms ease, box-shadow 250ms ease;
  display: flex;
  height: calc(var(--unit) * 6);
  flex-direction: row;
  align-items: center;
  gap: calc(var(--unit) * 2);
  padding: 0 calc((var(--unit) * 2) + var(--x-padding, calc(var(--unit) * 2)));
  text-align: left;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;

  &:last-child {
    border-bottom: 0;
  }

  &:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.01);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1), inset 0 -1px 0 0 rgba(255, 255, 255, 0.1);

    .ocr, .comments, .date, .typeCount {
      opacity: 1;
    }
  }

  &:active {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1), inset 0 -1px 0 0 rgba(255, 255, 255, 0.1), inset 0 3px 12px 0 rgba(0, 0, 0, 0.25);
  }
}

.iconCell {
  vertical-align: middle;
  text-align: center;

  & svg {
    vertical-align: middle;
  }

  .fileIcon {
    width: inherit;
    height: inherit;
  }

  .icon {
    display: flex;
    width: 24px;
    flex-shrink: 0;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    margin: auto;
    color: white;
    text-align: left;
  }
}

.ocr, .comments {
  transition: opacity 250ms ease;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-variant: tabular-nums;
  color: white;
  opacity: 0.5;
  vertical-align: middle;

  .enabled {
    border: 1px solid rgba(255, 255, 255, 0.35);
    border-radius: 4px;
    font-size: 12px;
    padding: 4px;
  }

  .commentsCount {
    padding: 0 0 0 5px;
  }
}

.typeCount {
  transition: opacity 250ms ease;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-self: center;
  border-radius: 30px;
  border: 2px solid currentColor;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 600;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  color: white;
  white-space: pre-wrap;
  vertical-align: middle;

  @media screen and (max-width: 600px) {
    .type {
      display: none;
    }
  }

  &.typeCountIMAGE {
    // background: linear-gradient(to bottom, #0e4edc, #3690c5);
    color: #0e4edc;
  }

  &.typeCountVIDEO {
    // background: linear-gradient(to bottom, #6b0c64, #de4c35);
    color: #de4c35;
  }

  &.typeCountAUDIO{
    // background: linear-gradient(to bottom, #6b4e0c, #dea235);
    color: #dea235;
  }

  &.typeCountUNKNOWN {
    // background: linear-gradient(to bottom, #525252, #797979);
    color: #797979;
  }
}

.date, .views {
  transition: opacity 250ms ease;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  justify-content: flex-end;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  text-align: right;
  font-variant: tabular-nums;
  color: white;
  opacity: 0.5;
  vertical-align: middle;
}

.date {
  width: 150px;
}

.views {
  width: 75px;
  padding-right: 0;
}

.name {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: white;
  overflow: hidden;
  vertical-align: middle;

  .fileName {
    flex-grow: 0;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .suffix {
    flex-shrink: 0;
    flex-grow: 0;
  }
}

@media screen and (max-width: 600px) {
  // .iconCell {
  //   padding-left: 12px;
  // }

  .date, .views {
    display: none;
  }
}